import React from 'react'
import OrderSucessImage from "../asset/OrderRecieved.png"
import { Link } from 'react-router-dom'
const OrderSuccess = () => {
  return (
    <div style={{ fontFamily: "DM Sans" }} className='h-[100vh] w-full flex items-center justify-center ' >
        <div>
            <img className='w-[350px]' src={OrderSucessImage} alt="" />
            <p className='text-center text-[1.5rem] font-[600] ' > Order Sucessfully Placed</p>
            <div className='mt-6 flex items-center justify-center ' >
              <Link to="/myOrders" >  
              <button className='px-6 py-3 border-[1px] border-blue-400 text-blue-400 rounded-xl  ' >View Orders</button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default OrderSuccess