import React from 'react'
import BackCamera from "../../asset/offer/back-camera.png"
import Display from "../../asset/offer/display-size.png"
import Rotate from "../../asset/offer/rotate.png"
import Sensor from "../../asset/offer/sensor.png"
import Touchscreen from "../../asset/offer/tap.png"
import Charge from "../../asset/offer/usb-port.png"
import Volume from "../../asset/offer/volume.png"
const WhatWillOffer = () => {
  return (
    <div className="lg:py-4 py-4 xl:px-6" style={{ fontFamily: "DM Sans" }}>
        <div className='lg:px-8 lg:ml-16 px-3 lg:py-12' >
            <h4 className=' text-[2rem] font-[600] ' >What We Offer</h4>
            <p className='text-[.9rem] lg:w-[650px] text-gray-600 ' >With 1 year warranty our each and every mobile device undergoes an extensive evaluation process consisting of a rigorous check across a total of 60 essential parameters like:</p>
            <div className='flex flex-wrap items-center gap-2 lg:gap-3 justify-start my-3 '  >
                <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={Sensor} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] ' >All Sensors</p>
                </div>
                <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={Display} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] '>Display</p>
                </div>
                   <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={Rotate} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] '>Front Camera</p>
                </div>  
                 <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={BackCamera} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] '>Back Camera</p>
                </div>
                 <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={Touchscreen} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] '>Touch Screen</p>
                </div>
                 <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={Charge} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] '>Charging Point</p>
                </div>
                   <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    <img className='lg:w-[40px] w-[25px]' src={Volume} alt="" />
                    <p className='lg:text-[1rem] text-[.6rem] '>Volume</p>
                </div>
                 <div className='bg-gray-200 lg:w-[150px] w-[90px]  rounded-[10px] flex items-center justify-center flex-col lg:px-3 lg:py-4 px-2 py-2' >
                    
                    <p className='lg:text-[1rem] text-[.6rem] '>and so on...</p>
                </div>
                
            </div>
            <p className='text-gray-600' >All these parts are checked thoroughly by our expert technicians and only 100% genuine arts* are used in the process. We offer affordable upgrades to your lifestyle as we believe sustainability can come with a smart mindset and economic approach.</p>
        </div>
    </div>
  )
}

export default WhatWillOffer