import React from 'react'
import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { BellAlertIcon } from '@heroicons/react/24/outline'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}
const Specification = ({product,addProduct,handleNotify}) => {
const [open, setOpen] = React.useState(1);
const handleOpen = (value) => setOpen(open === value ? 0 : value);
  return (
    <div style={{fontStyle:"DM Sans"}} className='lg:my-8 my-0 col-span-2 xl:mr-4 mx-4' >
        <div className='bg-white rounded-[20px]  py-0' >
            <div className=' py-2  ' >
                <h4 className='text-[1.4rem] font-[600] text-[#121212]' >Specifications</h4>
            </div>
            <div className='my-2' >
               <Accordion className='' open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader className='bg-gray-200 px-6 font-[500] text-[1rem] text-black rounded-[10px] border-none' onClick={() => handleOpen(1)}>Basic Informations:</AccordionHeader>
        <AccordionBody className=" border-gray-200 border-t-none rounded-t-none border-b-[1px] border-r-[1px] border-l-[1px] rounded-[10px]" >
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Storage</span> </p>
               <p className='lg:text-[1rem] text-[0.9rem] my-2 text-[#121212] flex items-start justify-start col-span-1 ' >{product?.ram} / {product?.storage} </p>
               
         </div>
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Chipset</span> </p>
               <p className='lg:text-[1rem] text-[0.9rem] my-2 text-[#121212] flex items-start justify-start col-span-1 ' >{product?.brandSpecifications && product?.brandSpecifications[1]?.details[5]?.value}</p>
               
         </div>
           <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' >  Display Resolution</span> </p>
               <p className='lg:text-[1rem] text-[0.9rem] my-2 text-[#121212] flex items-start justify-start col-span-1 ' >{product?.brandSpecifications && product?.brandSpecifications[1]?.details[1]?.value} </p>
               
         </div>
          <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' >  OS Type</span> </p>
               <p className='lg:text-[1rem] text-[0.9rem] my-2 text-[#121212] flex items-start justify-start col-span-1 ' >{product?.brandSpecifications && product?.brandSpecifications[6]?.details[0]?.value} </p>
               
         </div>
             <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' >  CPU</span> </p>
               <p className='lg:text-[1rem] text-[0.9rem] my-2 text-[#121212] flex items-start justify-start col-span-1 ' >{product?.brandSpecifications && product?.brandSpecifications[6]?.details[2]?.value} </p>
               
         </div>
          <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' >  GPU</span> </p>
               <p className='lg:text-[1rem] text-[0.9rem] my-2 text-[#121212] flex items-start justify-start col-span-1 ' >{product?.brandSpecifications && product?.brandSpecifications[6]?.details[3]?.value} </p>
               
         </div>
        </AccordionBody>
      </Accordion>
            </div>
            <div className='my-2' >
               <Accordion className='' open={open === 2} icon={<Icon id={2} open={open} />}>
        <AccordionHeader className='bg-gray-200 px-6 text-[1rem] text-black font-[500]  rounded-[10px] border-none' onClick={() => handleOpen(2)}>Camera Information:</AccordionHeader>
        <AccordionBody className=" border-gray-200 border-t-none rounded-t-none border-b-[1px] border-r-[1px] border-l-[1px] rounded-[10px]" >
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Front Camera</span> </p>
          <div className='col-span-1' >
                 {
               product?.brandSpecifications &&   product?.brandSpecifications[9]?.details?.map((specs)=>(
                      <div >
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>•{specs.name}:  {specs.value}</p>
                      </div>
                  ))
                }
                </div>
               
         </div>
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Back Camera</span> </p>
              <div className='col-span-1' >
                {
                  product?.brandSpecifications &&  product?.brandSpecifications[8]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212]  text-[0.5rem] xl:text-[1rem] '>•{specs.name}: {specs.value}</p>
                      </div>
                  ))
                }
               </div>
         </div>
          
        </AccordionBody>
      </Accordion>
            </div>
            <div className='my-2' >
               <Accordion className='' open={open === 3} icon={<Icon id={3} open={open} />}>
        <AccordionHeader className='bg-gray-200 px-6 text-[1rem] text-black font-[500]  rounded-[10px] border-none' onClick={() => handleOpen(3)}>Other Information:</AccordionHeader>
        <AccordionBody className=" border-gray-200 border-t-none rounded-t-none border-b-[1px] border-r-[1px] border-l-[1px] rounded-[10px]" >
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Sensors</span> </p>
          <div className='col-span-1' >
                {
                  product?.brandSpecifications &&  product?.brandSpecifications[12]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>{specs.value}</p>
                      </div>
                  ))
                }
                </div>
               
         </div>
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Battery</span> </p>
              <div className='col-span-1' >
               {
                  product?.brandSpecifications &&  product?.brandSpecifications[13]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>•{specs.name} : {specs.value}</p>
                      </div>
                  ))
                }
               </div>
         </div>
         <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Network</span> </p>
              <div className='col-span-1' >
               {
                  product?.brandSpecifications &&  product?.brandSpecifications[11]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>•{specs.name} : {specs.value}</p>
                      </div>
                  ))
                }
               </div>
         </div>
           <div className='border-b-[1px] py-2  grid grid-cols-2' >
          <p className=' lg:text-[1rem] ml-6 text-[0.9rem] my-2 text-gray-600 font-[500] flex items-start justify-start col-span-1 ' ><span className='' > Sound</span> </p>
              <div className='col-span-1' >
              {
                  product?.brandSpecifications &&  product?.brandSpecifications[10]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>{specs.name}: {specs.value}</p>
                      </div>
                  ))
                }
               </div>
         </div>
          
        </AccordionBody>
      </Accordion>
            </div>
            {/* <div className='my-4 ' >
            
                
                </div> 
               
                   <div className='border-b-[1px] px-3 xl:px-8 pt-3 pb-2' >
            <h4 className='text-[1.4rem]  font-[500] ' >Other Information:</h4>
            <div className='border-[1px] mb-3 border-gray-500 w-[150px] ' ></div>
            <div className='grid grid-cols-5 my-3 w-[800] items-center justify-start mt-6' >
               <p className=' lg:text-[1.2rem] text-[0.7rem]  text-gray-600 flex items-center justify-start col-span-1 ' ><span className='' > Sensors:</span> </p>
              <div className='col-span-4' >
                {
                  product?.brandSpecifications &&  product?.brandSpecifications[12]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>{specs.value}</p>
                      </div>
                  ))
                }
               </div>
               </div>
               <div className='grid grid-cols-5 w-[800] items-center justify-start mt-6' >
               <p className=' lg:text-[1.2rem] text-[0.7rem] my-3 text-gray-600 flex items-center justify-start col-span-1 ' ><span className='' > Battery:</span> </p>
              <div className='col-span-4' >
                {
                  product?.brandSpecifications &&  product?.brandSpecifications[13]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>•{specs.name} : {specs.value}</p>
                      </div>
                  ))
                }
               </div>
               </div>
               <div className='grid grid-cols-5 w-[800] items-center justify-start mt-6' >
               <p className=' lg:text-[1.2rem] text-[0.7rem] my-3 text-gray-600 flex items-center justify-start col-span-1 ' ><span className='' > Network:</span> </p>
              <div className='col-span-4' >
                {
                  product?.brandSpecifications &&  product?.brandSpecifications[11]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>•{specs.name} : {specs.value}</p>
                      </div>
                  ))
                }
               </div>
               </div>
                <div className='grid grid-cols-5 w-[800] items-center justify-start mt-6' >
               <p className=' lg:text-[1.2rem] text-[0.7rem] my-3 text-gray-600 flex items-center justify-start col-span-1 ' ><span className='' > Sound:</span> </p>
              <div className='col-span-4' >
                {
                  product?.brandSpecifications &&  product?.brandSpecifications[10]?.details?.map((specs)=>(
                      <div>
                        <p className='text-[#121212] text-[0.5rem] xl:text-[1rem]'>{specs.name}: {specs.value}</p>
                      </div>
                  ))
                }
               </div>
               </div>
               
               
                </div> 
                
                <div>

                </div>
                {/* <p className=' text-[1.4rem] my-2 text-gray-500 ' ><strong className='text-[#121212]' > Front Camera:</strong> {product?.brandSpecifications?.more_specification[7]?.data} </p> */}
              
                  
                {/* { product.stock === "in stock" ?   <div className='flex items-center justify-center' >
                <button onClick={addProduct} className='bg-[#1287E3] px-8 py-3 text-white text-[1.5rem] w-full xl:w-[250px] my-4 rounded-[15px]  flex items-center justify-center xl:justify-start ' >
               <ShoppingCartIcon className='mr-3 w-[40px] ' />  <p className='font-[500]' >Add To Cart</p>   
                </button>
            </div> : 
            <div className='flex items-center justify-center'>
                <button onClick={handleNotify} className='bg-green-400 px-4 py-2 text-white text-[1.1rem] w-full xl:w-[160px] my-4 rounded-[15px]  flex items-center justify-center xl:justify-start ' >
               <BellAlertIcon className='mr-3 w-[30px] ' />  <p className='font-[500]' >Notify Me</p>   
                </button>
            </div>
            } */}
            {/* </div> */} 
        </div>
    </div>
  )
}

export default Specification