import React,{useState,useEffect} from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  
  Button,
} from "@material-tailwind/react";
import Logo from "../../asset/logo.png"
import GoogleIcon from "../../asset/search.png"
import { PhoneIcon,EnvelopeIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon, EyeIcon,EyeSlashIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const Email = ({handleGoogleLogin,handleRegister,active,setActive,email,password,setEmail,setPassword,showPassword,setShowPassword,emailError,emailErrorMessage,passwordError,passwordErrorMessage}) => {
  return (
    <div>
               <Card shadow={false} className="lg:w-[500px] py-3 px-6 ">
      <CardHeader
        variant="gradient"
        color='grey'
        shadow={false}
        floated={false}
        
        className="mb-0 grid h-20 place-items-center"
      >
      <img className='w-[80px]' src={Logo} alt="" />
        <Typography variant="h5" className="text-[#1287E3]" >
         Login to your Account
        </Typography>
      
      </CardHeader>
      
      <CardBody className="flex flex-col gap-4">
      <div className='flex items-center mb-4 flex-col justify-center' >
       <Button
        size="lg"
        variant="outlined"
        onClick={handleGoogleLogin}
        color="blue-gray"
        className="flex w-[260px] my-1  items-center justify-center gap-6"
      >
        <img src={GoogleIcon} alt="google" className="h-4 w-4" />
      <p className='text-[0.7rem]' > Continue with Google</p>  
      </Button>
   <Button
        size="lg"
        variant="outlined"
        onClick={()=>setActive('phone')}
        color="blue-gray"
        className="flex w-[260px]  items-center justify-center gap-6"
      >
      <PhoneIcon className='w-4 h-4' />
      <p className='text-[0.7rem]' > Continue with Phone</p>
    
      </Button> 
     
      </div>
          <Input type='email'  value={email} error={emailError ? true : false }  onChange={(e)=>{setEmail(e.target.value)}} label="Email" size="lg" />
           {emailError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {emailErrorMessage && emailErrorMessage}</p>}
         <div className="relative flex w-full ">
      <Input
        
        label="Password"
        value={password} 
        error={passwordError ? true : false }
        onChange={(e)=>{setPassword(e.target.value)}}
        type={showPassword ? "text" : "password"}
        className="pr-20"
        containerProps={{
          className: "min-w-0",
        }}
      />
      <Button
        size="sm"
        color={password ? "white" : "gray"}
        disabled={!password}
        onClick={()=>setShowPassword(!showPassword)}
        className="!absolute right-1 top-1 rounded"
      >
        {showPassword ? <EyeSlashIcon className='w-[15px] text-blue-500' /> :<EyeIcon className='w-[15px] text-blue-500' />}
      </Button>
      
    </div>
    {passwordError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {passwordErrorMessage && passwordErrorMessage}</p>}
      </CardBody>
      <CardFooter className="pt-0  ">
      <Link to="/forgotPassword">
      <div className='mb-4 flex items-cente justify-end ' >
        <button onClick={()=>setActive('forgot')} className='text-red-500 text-[0.8rem] ' >Forgot Password ?</button>
      </div>
      </Link>
        <Button onClick={handleRegister}  variant="solid" className='bg-[#1287E3]' fullWidth>
          Login
        </Button>
        <Typography variant="small" className="mt-6 flex justify-center">
        Do not have a account?
        <Link to="/register">
          <Typography
            
            variant="small"
           
            className="ml-1 text-[#1287E3] font-bold"
          >
            Sign Up
          </Typography>
          </Link>
        </Typography>
      </CardFooter>
    </Card>
    </div>
  )
}

export default Email