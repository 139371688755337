import React from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Rating, Progress
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
const AddReviewModal = ({isAuthenticated,stars,setStars,reviewContent,setReviewContent,handleRating,handleSubmit,open,setOpen}) => {
    const navigate = useNavigate()
 
  const handleOpen = () => {
    if(isAuthenticated){
      setOpen(!open)
    }
    else{
      navigate("/login")
    }
    
  };
  return (
    <>
       <button onClick={handleOpen} className='w-full border-green-400 border-[1px] mx-2 text-green-400 my-2 rounded-[10px] py-1 lg:py-3 ' >Write a Review</button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Add New Review</DialogHeader>
        <DialogBody divider>
        <form action="">
                <div>
               
                <Rating className='w-[50px] h-[50px] ratingStars ' value={stars} onChange={handleRating} />
                  </div>
                    <div>
                        <textarea value={reviewContent} onChange={(e)=>setReviewContent(e.target.value)}  type="text" className=' mt-2 text-[1.2rem] outline-none bg-white border-[1px] border-gray-300 rounded-[20px] h-[150px] w-full px-6 py-6 ' placeholder='Enter Your Review' />
                    </div>
                  
                </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button onClick={handleSubmit} variant="gradient" color="green" >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default AddReviewModal