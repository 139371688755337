import React, { useState, useEffect } from 'react'
import Navbar from '../components/Layout/Navbar'
import ProductImage from '../components/SingleProduct/ProductImage'
import ProductContent from '../components/SingleProduct/ProductContent'
import Assurance from '../components/SingleProduct/Assurance'
import Specification from '../components/SingleProduct/Specification'
import Review from '../components/SingleProduct/Review'
import Footer from '../components/Layout/Footer'
import { useParams } from 'react-router-dom'
import { ref, onValue, get } from "firebase/database";
import { useSelector, useDispatch } from 'react-redux'
import { doc, getDocs, onSnapshot, collection, deleteDoc, addDoc,query,and,where, or } from "firebase/firestore";
import { db, database } from '../firebase.config';
import { addItemsToCart } from '../actions/cartActions'
import { useNavigate } from 'react-router-dom';
import RelatedProducts from '../components/SingleProduct/RelatedProducts'
import MoreInformation from '../components/SingleProduct/MoreInformation'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import ChatbotButton from '../components/Layout/ChatbotButton'
const SingleProduct = () => {
  const { id } = useParams()
  const [product, setProduct] = useState({})
  const [Reviews, setReviews] = useState([])
  const [ratings, setRatings] = useState({
    ratingOne : 0,
    ratingTwo: 0,
    ratingThree: 0,
    ratingFour: 0,
    ratingFive: 0
  })
  const [relatedProduct, setRelatedProduct] = useState([])
  const [condition, setCondition] = useState(product.condition ? product.condition : '')
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { error, loading, isAuthenticated, user, userProfile } = useSelector(
    (state) => state.users
  );


  const handleNotify = async () => {
    const data = {
      userName: userProfile.name && userProfile.name,
      email: userProfile.email && userProfile.email,
      phone: userProfile.phone && userProfile.phone,
      productName: product.productName && product.productName,
      productId: id && id,
      date: new Date(),
    }
    await addDoc(collection(db, "notify"), data).then((res) => {
     toast("We Will Let You As Soon as Product is Avaliable")
    })
  }
  const fetchRelatedProducts = async () =>{
    if(product?.category !== undefined && product?.brand !== undefined){
        const productsCategoryQuery = query(collection(db, "products"),   or(where("category", "==", product.category && product.category),  where("brand", "==", product.brand && product.brand)))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setRelatedProduct((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    
  }

  const handleAddToCart = () => {
    if (isAuthenticated) {
      dispatch(addItemsToCart(product, id))
       toast("Product Added to Cart")
    }
    else {
      navigate('/login')
    }

  }

  useEffect(() => {

    const reviewCountRef = ref(database, 'reviews/' + id);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));

        setReviews(newProducts)
        if(data.rating === 5){
          setRatings((prev)=>({...ratings, ratingFive:ratings.ratingFive+1  }))
        }
        if(data.rating === 4){
          setRatings((prev)=>({...ratings, ratingFour:ratings.ratingFour+1  }))
        }
        if(data.rating === 3){
          setRatings((prev)=>({...ratings, ratingThree:ratings.ratingThree+1  }))
        }
        if(data.rating === 2){
          setRatings((prev)=>({...ratings, ratingTwo:ratings.ratingTwo+1  }))
        }
        if(data.rating === 1){
          setRatings((prev)=>({...ratings, ratingOne:ratings.ratingOne+1  }))
        }
      }
   


    },);
    onSnapshot(doc(db, "products", id), (doc) => {
      console.log("Current data: ", doc.data());

      const data = doc.data()
      setProduct(data);
       setCondition(data.condition ? data.condition : '')

    });
       fetchRelatedProducts()
  }, [id,product?.category,product.brand])
  console.log(product)
  console.log(Reviews)
  return (
    <div style={{ fontStyle: "DM Sans" }} className='bg-white'>
      <Navbar />
      <ChatbotButton />
      <div className='lg:grid lg:grid-cols-5  px-2 py-4 grid-flow-col lg:px-8 xl:px-20 xl:py-16' >
        <ProductImage product={product} />
        <ProductContent handleNotify={handleNotify} addProduct={handleAddToCart} product={product} />
        <ToastContainer />
      </div>
      <div className='border-gray-200 border-[1px] my-3 xl:mx-8 mx-6  ' ></div>
      <Assurance />
      <RelatedProducts relatedProduct={relatedProduct} />
      <div className='lg:grid grid-cols-3 grid-flow-col-reverse' >
      <MoreInformation product={product} />
      <Specification handleNotify={handleNotify} addProduct={handleAddToCart} product={product} />
      </div>
      <Review ratings={ratings} isAuthenticated={isAuthenticated} reviews={Reviews} id={id} condition={condition} setCondition={setCondition} userProfile={userProfile} />
      <Footer />
    </div>
  )
}

export default SingleProduct