import React from 'react'
import ContactBannerImage from "../../asset/contactBanner.png"
import ContactImage from "../../asset/contactBanner.png"
const ContactBanner = () => {
  return (
     <div style={{fontFamily:"DM Sans"}} >
            <div className='lg:px-6 px-2 py-2 lg:py-6' >
                <img className=' rounded-[10px] lg:rounded-[20px] w-full  ' src={ContactImage} alt="" />
            </div>
    </div>
  )
}

export default ContactBanner