import React,{useState,useEffect} from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Button,
} from "@material-tailwind/react";
import GoogleIcon from "../asset/search.png"
import {useDispatch,useSelector} from "react-redux"
import {Link, useNavigate} from "react-router-dom"
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import { db } from '../firebase.config';
import { signUpUsingEmail, signUpUsingGoogle } from '../actions/userActions';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Logo from "../asset/logo.png"
const Register = () => {

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneError] = useState(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const [active, setActive] = useState("email")
    const [showPassword, setShowPassword] = useState(false)
    const { error, loading, isAuthenticated,users } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch()
      const navigate = useNavigate()
  const handleGoogleRegister = async () =>{
    dispatch(signUpUsingGoogle())
  }
  const handleRegister = async ()=>{
    
    const userRef = collection(db, "user");
    const emailQuery = query(userRef, where("email", "==", email));
    const getEmailInfo = await getDocs(emailQuery);
    const userMobileRef = collection(db, "user");
    const mobileQuery = query(userRef, where("phone", "==", phone));
    const getMobileInfo = await getDocs(mobileQuery);
      var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
      if(name===""){
        alert("Enter a name")
      }
    if(email!=="" && getEmailInfo.docs.length!==0 ){
    
      alert("Email Already Present")
      setEmailError(true)
      setEmailErrorMessage("Email is Already Present")
    }
   if(email===""  || emailRegex.test(email)===false){
      alert("Enter Valid Email")
      setEmailError(true)
      setEmailErrorMessage("Enter a Valid Email")
    }
     if( emailRegex.test(email)===true){
     setEmailError(false)
    }
    
    if(phone!=="" && getMobileInfo.docs.length!==0){
       alert("Phone Number Already Present")
      setPhoneError(true)
      setPhoneErrorMessage("Phone Number is Already Present")
    }
      if(phone===""  || phoneRegex.test(phone)!==true){
      alert("Enter Valid Phone Number")
      setPhoneError(true)
      setPhoneErrorMessage("Enter a Valid Phone Number")
    }
     if(password===""  ){
      alert("Enter Valid Password")
      setPasswordError(true)
      setPasswordErrorMessage("Enter a Valid Password")
    }
    else{
     dispatch(signUpUsingEmail(email,password,name,phone))
    if(!error){
      setName('')
      setEmail('')
      setPassword('')
      setPhone('')
    }
    }
    
  }
  

  useEffect(() => {
    if(isAuthenticated){
      navigate("/")
    }
  }, [isAuthenticated])

  return (
    <div className='w-full  h-[100vh] bg-[#e0e7ed] flex items-center justify-center ' >
         <Card shadow={false} className="xl:w-[550px] w-full mx-2 xl:mx-0 py-3 px-6 ">
      <CardHeader
        variant="gradient"
        color='grey'
        shadow={false}
        floated={false}
        
        className="mb-0 grid h-20 place-items-center"
      >
        <img className='w-[80px]' src={Logo} alt="" />
        <Typography variant="h5" className="text-[#1287E3]" >
          Create a New Account
        </Typography>
      
      </CardHeader>
      
      <CardBody className="flex flex-col gap-4">
      <div className='flex items-center justify-center' >
       <Button
       onClick={handleGoogleRegister}
        size="lg"
        variant="outlined"
        color="blue-gray"
        className="flex w-[280px]  items-center justify-center gap-4"
      >
        <img src={GoogleIcon} alt="google" className="h-4 w-4" />
      <p className='text-[0.7rem]' > Continue with Google</p>  
      </Button>
      </div>
        <Input value={name} onChange={(e)=>{setName(e.target.value)}} label="Name" size="lg" />
          <Input type='email' error={emailError ? true : false } value={email} onChange={(e)=>setEmail(e.target.value)} label="Email" size="lg" />
           {emailError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {emailErrorMessage && emailErrorMessage}</p>}
        <Input value={phone} error={phoneError ? true : false } onChange={(e)=>{setPhone(e.target.value)}} label="Phone" size="lg" />
          {phoneError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {phoneErrorMessage && phoneErrorMessage}</p>}
        <div className="relative flex w-full ">
      <Input
        
        label="Password"
        value={password} 
        error={passwordError ? true : false }
        onChange={(e)=>{setPassword(e.target.value)}}
        type={showPassword ? "text" : "password"}
        className="pr-20"
        containerProps={{
          className: "min-w-0",
        }}
      />
      <Button
        size="sm"
        color={password ? "blue" : "blue-gray"}
        disabled={!password}
        onClick={()=>setShowPassword(!showPassword)}
        className="!absolute right-1 top-1 rounded"
      >
        {showPassword ? "hide" : "show"}
      </Button>
      
    </div>
    {passwordError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {passwordErrorMessage && passwordErrorMessage}</p>}
       
   
      </CardBody>
      <CardFooter className="pt-0">
        <Button onClick={handleRegister}  variant="solid" className='bg-[#1287E3]' fullWidth>
          Sign Up
        </Button>
        <Typography variant="small" className="mt-6 flex justify-center">
         Already Registered?
         <Link to="/login">
          <Typography
            
            variant="small"
           
            className="ml-1 text-[#1287E3] font-bold"
          >
            Sign In
          </Typography>
          </Link>
        </Typography>
      </CardFooter>
    </Card>
    </div>
  )
}

export default Register