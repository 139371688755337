import React,{useState} from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  
  Button,
} from "@material-tailwind/react";
import {  sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase.config';
const ForgotPassword = () => {
    const [email, setEmail] = useState("")
    const [emailSend, setEmailSend] = useState(false)
    const handleVerification = () =>{
        sendPasswordResetEmail(auth, email)
  .then(() => {
   setEmailSend(true)
   setEmail("")
  })
  .catch((error) => {
    alert(error.message)
    // ..
  });
    }
  return (
    <div style={{ fontFamily: "DM Sans" }} className='w-full h-[100vh] bg-[#e0e7ed] flex items-center justify-center '>
         <div>
               <Card shadow={false} className="lg:w-[500px] py-3 px-6 ">
      <CardHeader
        variant="gradient"
        color='grey'
        shadow={false}
        floated={false}
        
        className="mb-0 grid h-10 place-items-center"
      >
        <Typography variant="h5" className="text-[#1287E3]" >
          Enter Email
        </Typography>
      
      </CardHeader>
      
      <CardBody className="flex flex-col gap-4">
        {emailSend && <p className='text-green-400 my-2' >Email Send Sucessfully</p>}
          <Input type='email'  value={email}   onChange={(e)=>{setEmail(e.target.value)}} label="Email" size="lg" />
        
        
      
      </CardBody>
      <CardFooter className="pt-0  ">
     
        <Button onClick={handleVerification}  variant="solid" className='bg-[#1287E3]' fullWidth>
          Send Reset Password Email
        </Button>
        
      </CardFooter>
    </Card>
    </div>
    </div>
  )
}

export default ForgotPassword