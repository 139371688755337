import React, { useState, useEffect } from 'react'
import Navbar from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import { useParams } from 'react-router-dom'
import ProductRight from '../components/ProductList/ProductRight'
import Filter from '../components/ProductList/Filter'
import { doc, getDocs, onSnapshot, collection, deleteDoc, query, where, or, and, orderBy, limit, startAfter, startAt, endBefore } from "firebase/firestore";
import { db } from '../firebase.config';
import { brandData } from '../data/BrandData'
import { categoryData } from '../data/CategoryData'
import ChatbotButton from '../components/Layout/ChatbotButton'
const ProductList = () => {
  const { brand, category, price, type } = useParams()
  console.log(price)
  console.log(type)
  console.log(brand)
  const [products, setProducts] = useState([])

  const [mainProductData, setMainProductData] = useState([])
  const [prevActive, setPrevActive] = useState(false)
  const [brandName, setBrandName] = useState([])
  const [ram, setram] = useState([])
  const [stock, setStock] = useState([])
  const [categoryName, setCategoryName] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [brandList, setBrandList] = useState([])
  const [min, setmin] = useState(0)
  const [max, setMax] = useState(100000)
  const [condition, setCondition] = useState([])
  console.log(categoryName)
  console.log(brandName)
  const fetchProduct = async () => {

    setProducts([])
    if (min !== 0 && max !== 0) {
      console.log(min, max)
      console.log("queried")
      const productsCategoryQuery = query(collection(db, "products"), and(where("price", "<=", Number(max)), where("price", ">", Number(min))))
      const querySnapshot = await getDocs(productsCategoryQuery);
      console.log(querySnapshot)
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        console.log(data)
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    else if (brandName.length !== 0 && brandName !== []) {
      console.log("object", brandName)
      const productsCategoryQuery = query(collection(db, "products"), where("brand", "in", brandName))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    else if (categoryName.length !== 0 && categoryName !== []) {
      const productsCategoryQuery = query(collection(db, "products"), where("category", "in", categoryName))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    else if (ram.length !== 0 && ram !== []) {
      const productsCategoryQuery = query(collection(db, "products"), where("ram", "in", ram))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
     else if (condition.length !== 0 && condition !== []) {
      const productsCategoryQuery = query(collection(db, "products"), where("condition", "in", condition))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    else if (stock.length !== 0 && stock !== []) {
      const productsCategoryQuery = query(collection(db, "products"), where("stock", "in", stock))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    else if (price !== undefined) {
      const productsBrandQuery = query(collection(db, "products"), where("price", "<=", Number(price)))
      const querySnapshot = await getDocs(productsBrandQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    else if (brand !== undefined) {
      
      const productsBrandQuery = query(collection(db, "products"), where("brand", "==", brand))
      const querySnapshot = await getDocs(productsBrandQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
     else if (type !== undefined) {

      const productsBrandQuery = query(collection(db, "products"), where("type", "==", type))
      const querySnapshot = await getDocs(productsBrandQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }
    
    else if (category !== undefined) {
      console.log(category)
     
      const productsCategoryQuery = query(collection(db, "products"), where("category", "==", category))
      const querySnapshot = await getDocs(productsCategoryQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
      console.log(products)
    }



    else {
      const productsQuery = query(collection(db, "products"), orderBy("createdAt", "desc"))
      const querySnapshot = await getDocs(productsQuery);
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        setProducts((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })
    }


  }

  const fetchBrandList = async () => {
    setBrandList([])
    setCategoryList([])
    const productsQuery = query(collection(db, "products"))
    const querySnapshot = await getDocs(productsQuery);
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      if (data) {

        brandData.map((brand) => {
          if (brand.name === data.brand) {
            setBrandList((prev) => {
              return [...prev, !prev.includes(brand) && brand]
            })
          }
        })
        categoryData.map((category) => {
          if (category.value === data.category) {

            setCategoryList((prev) => {
              return [...prev, !prev.includes(category) && category]
            })
          }
        })

      }
    })
  }

  useEffect(() => {
    //     const repairCountRef = ref(database, 'products/' );

    //    onValue(repairCountRef, (snapshot) => {
    //   const data = snapshot.val();


    //       const newProducts = Object.keys(data).map(key=>({
    //     id:key,
    //     ...data[key]
    //   }));

    //      if(newProducts!==[] && brand){
    //    const newProduct =[]
    //    newProducts.map((product)=>{
    //     if(product.brand===brand){
    //       newProduct.push(product)
    //     }
    //    })
    //    setProducts(newProduct)
    //   }
    //   else{
    //     setProducts(newProducts)
    //   }

    // },);

    fetchProduct()
    fetchBrandList()

  }, [brandName, categoryName, brand, category, min, max, stock, ram,price,condition,type])

  console.log(products)
  return (
    <div style={{ fontFamily: "DM Sans" }} className='bg-[#e9f3f9]' >
      <Navbar />
      <ChatbotButton />
     
      <div className='lg:grid grid-cols-1 lg:grid-cols-3 grid-flow-col items-start justify-start px-2 py-3 lg:px-6 xl:px-12 lg:py-6 ' >
        <Filter condition={condition} setCondition={setCondition} stock={stock} setStock={setStock} ram={ram} setram={setram} fetchProduct={fetchProduct} categoryList={categoryList} min={min} setmin={setmin} max={max} setMax={setMax} categoryName={categoryName} setCategoryName={setCategoryName} brandName={brandName} setBrandName={setBrandName} brandList={brandList} />

        {products.length !== 0 ? <ProductRight products={products} /> : (
          <div className='lg:col-span-2 my-12 '>
            <h4 className='text-[1.5rem] text-center ' >No Products Available!</h4>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default ProductList