import React, { useEffect, useState } from "react";
import Navbar from "../components/Layout/Navbar";
import HomeBanner from "../components/home/HomeBanner";
import HomeCarousel from "../components/home/HomeCarousel";
import BrandList from "../components/home/BrandList";
import LeftRight from "../components/home/LeftRight";
import Footer from "../components/Layout/Footer";
import HotDeals from "../components/home/HotDeals";
import FeaturedMobiles from "../components/home/FeaturedMobiles";
import MovingText from "../components/home/MovingText";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../actions/userActions";
import Loading from "../components/Layout/Loading";
import Banners from "../components/home/Banners";
import ShopByCategory from "../components/home/ShopByCategory";
import { ref, onValue, get} from "firebase/database";
import {database} from "../firebase.config"
import Assurance from "../components/home/Assurance";
import WhyReviwo from "../components/home/WhyReviwo";
import Faqs from "../components/home/Faqs";
import { brandData } from "../data/BrandData";
import { doc, getDocs, onSnapshot,collection,deleteDoc, where, query } from "firebase/firestore";
import { db } from '../firebase.config';
import SteptoBuy from "../components/home/SteptoBuy";
import ShoppingData from "../components/home/ShoppingData";
import DiscoverMore from "../components/home/DiscoverMore";
import ShopByPrice from "../components/home/ShopByPrice";
import ReactWhatsapp from 'react-whatsapp';
import ChatbotButton from "../components/Layout/ChatbotButton";
const Home = () => {
  const { error, loading, isAuthenticated, user, userProfile } = useSelector(
    (state) => state.users
  );
  const [products, setProducts] = useState([])
  const [newlyLaunched, setNewlyLaunched] = useState([])
    const [premiumMobiles, setPremiumMobiles] = useState([])
    const [brandList, setBrandList] = useState([])
    const [offerBanner, setOfferBanner] = useState([])
    const [homeBanner, sethomeBanner] = useState([])
  const dispatch = useDispatch();
    const fetchProducts =  async () =>{
     const querySnapshot = await getDocs(collection(db, "products"));
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      if(data){
         if(data.category==="Premium"){
       setPremiumMobiles((prev)=>[...prev,{
      id:doc.id,
      ...data
     }])
      setProducts((prev)=>[...prev,{
      id:doc.id,
      ...data
     }])
    }
    else if(data.category==="NewArrival"){
        setNewlyLaunched((prev)=>[...prev,{
      id:doc.id,
      ...data
     }])
      setProducts((prev)=>[...prev,{
      id:doc.id,
      ...data
     }])
    }
     else{
          setProducts((prev)=>[...prev,{
      id:doc.id,
      ...data
     }])
      }
      brandData.map((brand)=>{
    if(brand.name===data.brand){
      setBrandList((prev)=>[...prev,brand])
    }
   })
      }
     
   
   
  
    })
       

    }

    const fetchOfferBanners = async () =>{
       const bannerQuery = await query(collection(db, "banners"), where("bannerName", "==" , "offer"));
       const querySnapshot = await getDocs(bannerQuery);
        querySnapshot.forEach((doc) => {
        const data = doc.data()
        setOfferBanner((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })

    }
    const fetchAllBanners = async ( ) =>{
       const bannerQuery = await query(collection(db, "banners"), where("bannerName", "==" , "home"));
       const querySnapshot = await getDocs(bannerQuery);
        querySnapshot.forEach((doc) => {
        const data = doc.data()
        sethomeBanner((prev) => [...prev, {
          id: doc.id,
          ...data
        }])

      })

    
    }
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadUser(user));
    }

 
    fetchProducts()
    fetchOfferBanners()
    fetchAllBanners()

  }, []);
  console.log(newlyLaunched)
  console.log(products)
  console.log(premiumMobiles)
  console.log(brandList)
  console.log(offerBanner)
  return (
    <div>
      {loading && isAuthenticated ? (
        <Loading />
      ) : (
        <div className="bg-[#ffffff]" >
  
          <Navbar />
      
          <HomeCarousel homeBanner={homeBanner} />
              <ChatbotButton />
       <SteptoBuy />
       
             <BrandList brandList={brandList} />
              
          <HotDeals premiumMobiles={premiumMobiles} />
            
           <ShopByCategory />
     
          <FeaturedMobiles newlyLaunched={newlyLaunched} />
       
          <ShopByPrice />
             <ShoppingData />
          <DiscoverMore offerBanner={offerBanner}  />
          <Faqs />
         
      
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
