import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import Premium from "../../asset/premiumCategory.png"
import NewArrival from "../../asset/newArrivalsCategory.png"
import CameraPhone from "../../asset/bestcameraCategory.png"
import SellingPhone from "../../asset/sellingfastCategory.png"
import TrendingPhone from "../../asset/trendingCategory.png"

const AllCategory = () => {
  return (
    <div className='xl:px-12 px-2 py-4 lg:py-16' >
    <div  >
    <h4 className='font-[700] text-[1.5rem] lg:text-[2.5rem] text-center ' >Choose a Category</h4>
    <div className='flex items-center justify-center gap-4 lg:gap-8  my-6 flex-wrap' >
    <Link to="/shop/Premium">
        
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[90px] mr-3 lg:rounded-[10px] rounded-[5px]' src={Premium} alt="" />
      
        </Link>
          <Link to="/shop/CameraPhone">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[90px] mr-3 lg:rounded-[10px] rounded-[5px]' src={CameraPhone} alt="" />
        </Link>
           <Link to="/shop/SellingFast">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[90px] mr-3 lg:rounded-[10px] rounded-[5px]' src={SellingPhone} alt="" />
        </Link>
          <Link to="/shop/NewArrival">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[90px] mr-3 lg:rounded-[10px] rounded-[5px]' src={NewArrival} alt="" />
        </Link>
        <Link to="/shop/TrendingPhone">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[90px] mr-3 lg:rounded-[10px] rounded-[5px]' src={TrendingPhone} alt="" />
        </Link>
    </div>
    
    </div>
    </div>
  )
}

export default AllCategory