import React from 'react'
import Iphone from "../../asset/iphone.png"
import Oneplus from "../../asset/oneplus8.png"
import Realme from "../../asset/realme8.png"
import ProductIphoneOne from "../../asset/productIphone.jpg"
import ProductOneplusOne from "../../asset/productoneplus1.jpg"
import Productrealmeone from "../../asset/productrealmeone.jpg"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import ProductCard from './ProductCard'

const FeaturedMobiles = ({newlyLaunched}) => {
        const data = [
        {
            name:"Refurbished Iphone 14 Pro ",
            price:"Rs. 65,000",
            image:ProductIphoneOne,
        },
         {
            name:"Refurbished Oneplus 8t ",
            price:"Rs. 30,000",
            image:ProductOneplusOne,
        },
        {
            name:"Refurbished Realme 8 ",
            price:"Rs. 16,000",
            image:Productrealmeone,
        },
        {
            name:"Refurbished Iphone 14 Pro ",
            price:"Rs. 65,000",
            image:ProductIphoneOne,
        },
         {
            name:"Refurbished Oneplus 8t ",
            price:"Rs. 30,000",
            image:ProductOneplusOne,
        },
        {
            name:"Refurbished Realme 8 ",
            price:"Rs. 16,000",
            image:Productrealmeone,
        },
       
        ]
  return (
    <div style={{fontFamily:"DM Sans"}}  className='lg:py-2 py-2 px-3 lg:px-1' >
        <div className='lg:px-12' >
         {/* <p style={{fontFamily:"Bebas Neue"}} className='text-center tracking-widest text-[#1287E3] ' >Featured </p> */}
            <h1 className='font-[600] text-[1rem] lg:text-[1.5rem] ' >
               Newly Launched
            </h1>
            <div className='pb-6 pt-4 lg:pt-4' >
               <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          navigation={true}
          breakpoints={{
              300: {
          width: 300,
          slidesPerView: 1.1,
        },
         350: {
          width: 350,
          slidesPerView: 1.6,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
          1000:{
          width:1000,
          slidesPerView:3.1
        },
       
         1440:{
          width:1440,
          slidesPerView:4.2
        }
      }}
      style={{
         '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
          '--swiper-pagination-color': '#DE562C',
        }}
          autoplay={{
            delay: 4000,
            disableOnInteraction:true,
          }}
          loop={true}
          modules={[Autoplay,Navigation]}
         >
            {
               newlyLaunched && newlyLaunched.map((product)=>(
                    <SwiperSlide>
                    
                        <ProductCard product={product} />
                    </SwiperSlide>
                ))
            }
          </Swiper>
            </div>

        </div>
    </div>
  )
}

export default FeaturedMobiles