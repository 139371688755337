import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import RegisterIcon from "../../asset/steps/register.png"
import SearchIcon from "../../asset/steps/search.png"
import Addtocart from "../../asset/steps/Cart.png"
import Pay from "../../asset/steps/pay.png"
import Ship from "../../asset/steps/ship.png"
import { ChevronRightIcon } from '@heroicons/react/24/outline';
const SteptoBuy = () => {

  const stepsToBuyData = [
    {
      name:"Create a New Account",
      icon:RegisterIcon
    },
    {
      name:"Look for your desired device",
      icon:SearchIcon
    },
     {
      name:"Add the product to the cart ",
      icon:Addtocart
    },
    {
      name:"Select Payment Option ",
      icon:Pay
    },
    {
      name:"Get Your Product Delivered ",
      icon:Ship
    },
  ]
  return (
    <div style={{fontFamily:"DM Sans"}} className='xl:px-8 bg-[#f1f1f1]    lg:px-4 px-2 py-0  '>
       
       <div>
        <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          breakpoints={{
             300: {
          width: 350,
          slidesPerView: 2.5,
        },
        350: {
          width: 350,
          slidesPerView: 2.4,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
          1000:{
          width:1000,
          slidesPerView:4
        },
       
         1440:{
          width:1440,
          slidesPerView:5
        }
      }}
          autoplay={{
            delay: 2000,
            disableOnInteraction:false,
          }}
          loop={true}
          modules={[Autoplay]}
         >
            {
             stepsToBuyData && stepsToBuyData.map((step,index)=>(
              <SwiperSlide>
              <div className='flex    items-center justify-center '>
                <div className='flex 2xl:w-[220px]  xl:w-[180px] lg:w-[160px] w-[150px]   px-2 py-2   items-center justify-center ' >
                  <img className='xl:w-[60px] mr-3 lg:w-[50px] w-[25px]' src={step.icon} alt="" />
                  <h4 className='xl:text-[0.8rem] lg:text-[0.7rem] text-[.4rem]  text-[#121212]  font-[500]' >{step.name}</h4>
                </div>
               
                </div>
              </SwiperSlide>
             ))
            }
          </Swiper>
       </div>
    </div>
  )
}

export default SteptoBuy