import React,{useState,useEffect} from 'react'
import Navbar from '../components/Layout/Navbar'
import Footer from '../components/Layout/Footer'
import BrandList from '../components/Brand/BrandList'
import { doc, getDocs, onSnapshot,collection,deleteDoc } from "firebase/firestore";
import { db } from '../firebase.config';
import { brandData } from '../data/BrandData';
import ChatbotButton from '../components/Layout/ChatbotButton';
const ShopByBrand = () => {
  const [brandList, setBrandList] = useState([])
    const fetchProducts =  async () =>{
      let  brandListTotal = []
     const querySnapshot = await getDocs(collection(db, "products"));
    querySnapshot.forEach((doc) => {
      const data = doc.data()
      if(data){
         let  brandListTotal = []
         console.log(data.brand)
       
      brandData?.map((brand)=>{

    if(brand.name===data.brand ){
      setBrandList((prev)=>{
        
        return [...prev,!prev.includes(brand) && brand]})
  
     
    
    }
    
    
   })

 

      }
     
   
   
  
    })
        console.log(brandList)

    }
  useEffect(() => {
    fetchProducts()
   
  }, []);
  return (
    <div  className="bg-[#ffffff]">
        <Navbar />
        <BrandList brandList={brandList} />
        <ChatbotButton />
        <Footer />
    </div>
  )
}

export default ShopByBrand