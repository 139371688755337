import React from 'react'

const ContactForm = ({formData,setFormData,handleSubmit}) => {
  return (
    <div className='lg:py-12 py-4 xl:px-16' style={{ fontFamily: "DM Sans" }}>
        <div>
            <h4 className='font-[600] text-[1.5rem] lg:text-[2.5rem] text-center ' >Fill the Form and Enquire </h4>
            <div className='lg:py-12 py-2 ' >
                <form onSubmit={handleSubmit} action="">
                    <div className='flex items-center my-6 xl:justify-center flex-col px-6 xl:px-0 xl:flex-row gap-4 xl:gap-0' >
                        <input value={formData.name} onChange={(e)=>setFormData({...formData,name:e.target.value})} placeholder='Enter Your Name' className='xl:mr-6  border-[1px] border-gray-200 w-full xl:w-[350px] rounded-[10px] outline-none  px-4 py-4 ' type="text" />
                        <input value={formData.email} onChange={(e)=>setFormData({...formData,email:e.target.value})} placeholder='Enter Your Email' className='xl:mr-6  border-[1px] border-gray-200 w-full rounded-[10px] outline-none  xl:w-[350px] px-4 py-4 ' type="text" />
                    </div>
                    <div className='flex items-center my-6 xl:justify-center flex-col px-6 xl:px-0 xl:flex-row gap-4 xl:gap-0' >
                        <input value={formData.phone} onChange={(e)=>setFormData({...formData,phone:e.target.value})}  placeholder='Enter Your Contact Number' className='xl:mr-6 w-full border-[1px] border-gray-200 xl:w-[350px] rounded-[10px] outline-none  px-4 py-4 ' type="text" />
                        <input value={formData.subject} onChange={(e)=>setFormData({...formData,subject:e.target.value})} placeholder='Enter Your Subject' className='xl:mr-6 w-full border-[1px] border-gray-200 rounded-[10px] outline-none  xl:w-[350px] px-4 py-4 ' type="text" />
                    </div>
                      <div className='flex items-center my-6 xl:justify-center flex-col px-6 xl:px-0 xl:flex-row gap-4 xl:gap-0' >
                       <textarea value={formData.message} onChange={(e)=>setFormData({...formData,message:e.target.value})} placeholder='Enter your Message' className='xl:mr-6 w-full border-[1px] border-gray-200 rounded-[10px] outline-none xl:h-[250px]  xl:w-[730px] px-4 py-4 ' name="" id=""  />
                    </div>
                    <div className='flex px-6 xl:px-0 items-center justify-center' >
                        <button type='submit' className='text-[1.2rem] text-white font-[500] xl:w-[200px] w-full bg-[#1287E3] px-8 py-3 rounded-[10px] ' >Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ContactForm