import { ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import React,{useState} from 'react'

import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
const ShopByPrice = () => {
const [controlledSwiper, setControlledSwiper] = useState(null);
    const priceData = [
        {
            name:"9,999",
            url:9999
        },
        {
            name:"19,999",
            url:19999
        },
        {
            name:"29,999",
            url:29999
        },
         {
            name:"39,999",
            url:39999
        },
         {
            name:"49,999",
            url:49999
        },
         {
            name:"59,999",
            url:59999
        },
        {
            name:"69,999",
            url:69999
        },
        {
            name:"79,999",
            url:79999
        },
        {
            name:"89,999",
            url:89999
        },
        {
            name:"99,999",
            url:99999
        },
    ]

  return (
    <div style={{fontFamily:"DM Sans"}}  className='lg:py-6 py-3 px-3 lg:px-1'>
       <div className='lg:px-12'>
    <h4 className='font-[600] text-[1rem] lg:text-[1.5rem] '>Shop By Price</h4>
    <div className='mt-3' >
     <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          navigation={true}
          breakpoints={{
        300: {
          width: 300,
          slidesPerView: 2,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
          1000:{
          width:1000,
          slidesPerView:5
        },
       
         1440:{
          width:1440,
          slidesPerView:7
        }
      }}
          autoplay={{
            delay: 4000,
            disableOnInteraction:true,
          }}
           style={{
         '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
          '--swiper-pagination-color': '#DE562C',
        }}
          loop={false}
          modules={[Autoplay,Navigation]}
         >
            {
            priceData && priceData.map((data)=>(
                <SwiperSlide>
                <Link to={`/shop/price/${data.url}`} >
                <div className='bg-gradient-to-r from-cyan-500 to-blue-500 rounded-[10px] px-3 py-4 lg:px-4 lg:py-6 w-[140px] lg:w-[180px] ' >
                    <h4  className='lg:text-[1rem] text-[.7rem]' >under</h4>
                    <p className='mt-0 mb-3 font-[600] text-[1rem] lg:text-[1.3rem]' ><span className='font-[500]' style={{fontFamily:"Bebas Neue"}} >₹</span> {data.name}</p>
                    <div>
                        <ArrowRightCircleIcon className='lg:w-[40px] w-[25px] ' />
                    </div>
                </div>
                </Link>
                </SwiperSlide>
            ))
        }
          </Swiper>
       </div>
        </div>
    </div>
  )
}

export default ShopByPrice