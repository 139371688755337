import React from 'react'
import { Spinner } from '@material-tailwind/react'
const Loading = () => {
  return (
    <div className='w-full h-[100vh] flex items-center justify-center ' >
        <div className='' > 
             <Spinner className="h-12 w-12" />
        </div>
    </div>
  )
}

export default Loading