export const categoryData = [
    {
        name:"Premium Mobiles",
        value:"Premium"
    },
    {
        name:"New Arrival",
        value:"NewArrival"
    },
    {
        name:"Best Camera",
        value:"CameraPhone"
    },
    {
        name:"Selling Fast",
        value:"SellingFast"
    },
    {
        name:"Trending in the Market",
        value:"Trending"
    }
]