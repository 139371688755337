import React,{useState} from 'react'

import ReactWhatsapp from "react-whatsapp"
import Whatshapp from "../../asset/whatsapp.png"


const ChatbotButton = () => {
    const [chatBox, setChatBox] = useState(false)
  return (
    <div className='fixed z-[999] right-[30px] bottom-[20px]'>
    <div >
        <ReactWhatsapp className='flex items-center justify-center' number="+91 8282822979" message="Hii, I want to learn more about the course" >
          <img className='lg:w-[80px] w-[40px] ' src={Whatshapp} alt="" />
       
        </ReactWhatsapp>
    </div>
          
    </div>
  )
}

export default ChatbotButton