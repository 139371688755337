import React,{useState} from 'react'
import { ShoppingBagIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
const CartPrice = ({cartItems,totalItemPrice,totalRealItemPrice}) => {
    

  return (
    <div className='col-span-1 float xl:ml-6 h-[450px]  bg-white rounded-[10px]' >
        <div className='border-b-[1px] py-3 px-6 text-[1.2rem] text-gray-600 border-gray-200 ' >
            <p>Price Details</p>
        </div>
        <div className='flex items-center px-6 py-4 justify-between' >
            <p className='text-[1.1rem]' >
                Price ( {cartItems.length} items )
            </p>
            <p className='text-[1.1rem]'>
               Rs.{
                totalRealItemPrice
               }
            </p>
        </div>
         <div className='flex items-center px-6 py-4 justify-between' >
            <p className='text-[1.1rem]' >
                Discount
            </p>
            <p className='text-[1.1rem] text-green-500 '>
               - Rs.{
              totalRealItemPrice -  totalItemPrice 
               }
            </p>
        </div>
         <div className='flex items-center px-6 py-4 justify-between' >
            <p className='text-[1.1rem]' >
                Delivery
            </p>
            <p className='text-[1.1rem] text-green-500 '>
             Free
            </p>
        </div>
        <div className='flex items-center px-6 py-4 justify-between' >
            <p className='text-[1.1rem]' >
                GST
            </p>
            <p className='text-[1.1rem] text-green-500 '>
          
          + Rs. {totalItemPrice * 0.18}
            </p>
        </div>
         <div className='border-t-[1px] py-6 px-6 flex items-center  justify-between text-[1.2rem]  border-gray-200 ' >
             <p className='text-[1.1rem]' >
                Total
            </p>
            <p className='text-[1.3rem] font-[600] '>
            Rs. {totalItemPrice + (totalItemPrice * 0.18)}
            </p>
        </div>
        <div className='flex items-center justify-center' >
          <Link to="/checkout"> <button className='bg-[#1287E3] px-8 py-3 text-white text-[0.9rem] w-[full] my-4 rounded-[15px]  flex items-center justify-start ' >
               <ShoppingBagIcon className='mr-3 w-[30px] ' />  <p className='font-[500]' >Proceed to Checkout</p>   
                </button></Link> 
        </div>
    </div>
  )
}

export default CartPrice