import React from 'react'
import ReviwoWhite from "../../asset/revivowhite.png"
import Instagram from "../../asset/instagram.png"
import Linkedin from "../../asset/linkedin.png"
import Facebook from "../../asset/facebook.png"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className='py-0  mx-0 ' >
    <div style={{fontStyle:"DM Sans"}} className=' bg-[#121212] xl:px-16  xl:py-12 py-3  ' >
    <div className='flex items-center justify-center' >
    <img className='w-[150px]' src={ReviwoWhite} alt="" />
    </div>
        <div className='xl:px-6 px-2 py-2 border-b-[1px] border-gray-400 md:grid md:grid-cols-9 xl:items-start xl:justify-start grid-flow-col' >
            <div className='col-span-2 mx-3 my-3' >
                <p className='font-[600] text-white text-left text-[1.3rem] xl:text-[1.5rem] ' >Shop By Category</p>
                <div>
                 <Link to="/shop/Premium" >   <p className='text-left text-[#9c9c9c] my-2' >Premium Mobile Phones</p></Link>
                    <Link to="/shop/NewArrival" > <p className='text-left text-[#9c9c9c] my-2'>New Arrival </p></Link> 
                    <Link to="/shop/CameraPhone" > <p className='text-left text-[#9c9c9c] my-2'>Best Camera</p></Link> 
                    <Link to="/shop/SellingFast" > <p className='text-left text-[#9c9c9c] my-2'>Selling Out Fast</p></Link>
                    <Link to="/shop/Trending" > <p className='text-left text-[#9c9c9c] my-2'>Trending in the Market</p></Link>
                </div>
            </div>
            <div className='col-span-2 mx-3 my-3' >
             <p className='font-[600] text-white text-left text-[1.3rem] xl:text-[1.5rem] ' >Shop By Brands</p>
                <div>
                 <Link to="/shop/brand/Apple" >   <p className='text-left text-[#9c9c9c] my-2'>Apple</p></Link>
                   <Link to="/shop/brand/Samsung" >   <p className='text-left text-[#9c9c9c] my-2'>Samsung </p></Link>
                    <Link to="/shop/brand/Oppo" > <p className='text-left text-[#9c9c9c] my-2'>Oppo</p></Link>
                   <Link to="/shop/brand/Vivo" ><p className='text-left text-[#9c9c9c] my-2'>Vivo</p></Link> 
                </div></div>
            <div className='col-span-2 mx-3 my-3' >
                <p className='font-[600] text-white text-left text-[1.3rem] xl:text-[1.5rem] ' >Important Links</p>
                <div>
                  <Link to='/profile' > <p className='text-left text-[#9c9c9c] my-2' >My Profile</p></Link> 
                    <Link to='/myOrders' >  <p className='text-left text-[#9c9c9c] my-2'>My Orders </p></Link> 
                    <p className='text-left text-[#9c9c9c] my-2'>Terms and Condition</p>
                    <p className='text-left text-[#9c9c9c] my-2'>Privacy Policy</p>
                    <p className='text-left text-[#9c9c9c] my-2'>Refund Policy</p>
                </div>
            </div>
            <div className='col-span-3 mx-3 my-3' >
                 <p className='font-[600] text-white text-left text-[1.5rem] ' >Follows us on Social Media</p>
                <div className='flex items-center my-3 justify-start' >
                  <img className='xl:w-[40px] lg:w-[40px] w-[30px] mr-8 my' src={Instagram} alt="" />
                  <img className='xl:w-[40px] lg:w-[40px] w-[30px] mr-8 my' src={Facebook} alt="" />
                  <img className='xl:w-[40px] lg:w-[40px] w-[30px] mr-8 my' src={Linkedin} alt="" />
                </div>
                 <div className='my-2 text-left ' >
                    <p className='text-[#9c9c9c]' ><strong>Email : </strong> care@reviwo.com </p>
                    
                </div>
                <div className='my-2 text-left ' >
                    <p className='text-[#9c9c9c]' ><strong>Contact : </strong> +91 8282822979 </p>
                    
                </div>
                 <div className='my-2 text-left ' >
                    <p className='text-[#9c9c9c]' ><strong>Address : </strong> Unit No 30, VTM 1, Mehra Industrial Compound, Opp Telephone Exchange, Andheri Kurla Road, Saki Naka, Mumbai 400072</p>
                    
                </div>
            </div>
        </div>
        <div>
          <p className='text-gray-400' >© FIXZIP TECHNOLOGY PRIVATE LIMITED. All Righs Reserved</p>
        </div>
    </div>
    </div>
  )
}

export default Footer