import React from 'react'

const ReachUs = () => {
  return (
    <div className='py-4 px-4 xl:px-0' style={{ fontFamily: "DM Sans" }}>
        <h4 className='text-[3rem] font-[600] text-[#121212] text-center ' >Reach Us</h4>
        <div className='flex py-4 items-center justify-center flex-col xl:flex-row gap-4' >
            <div className='mx-6 rounded-[15px] w-full lg:h-[160px] bg-white px-6 py-8 lg:w-[500px]' >
                <p className='font-[500] text-[#1287E3] text-[1rem] lg:text-[1.4rem] ' >Email: <span className='text-gray-400' > care@reviwo.com</span></p>
                 <p className='font-[500] text-[#1287E3] text-[1rem] lg:text-[1.4rem] mt-3 ' >Phone: <span className='text-gray-400' > +91 8282822979</span></p>
            </div>
            <div className='mx-6 rounded-[15px] w-full lg:h-[160px] bg-white px-6 py-3 lg:w-[500px]' >
                 <p className='font-[500] text-[#1287E3] text-[1.3rem] ' >Address: </p>
                 <span className='text-gray-400 text-[0.7rem] lg:text-[0.9rem] ' > Unit No 30, VTM 1, Mehra Industrial Compound, Opp Telephone Exchange, Andheri Kurla Road, Saki Naka, Mumbai 400072Unit No 30, VTM 1, Mehra Industrial Compound, Opp Telephone Exchange, Andheri Kurla Road, Saki Naka, Mumbai 400072</span>
            </div>
        </div>
    </div>
  )
}

export default ReachUs