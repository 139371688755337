// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier,GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGehJvDRnMzo0pYf8BChgpSj2Owp5oosk",
  authDomain: "reviwo-2509a.firebaseapp.com",
  databaseURL: "https://reviwo-2509a-default-rtdb.firebaseio.com",
  projectId: "reviwo-2509a",
  storageBucket: "reviwo-2509a.appspot.com",
  messagingSenderId: "859318077880",
  appId: "1:859318077880:web:d9fdb7f459fb22c845274d",
  measurementId: "G-DNB2DKR1L4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth()
export const database = getDatabase(app)
export const  googleProvider = new GoogleAuthProvider()