import React from 'react'
import { Link } from 'react-router-dom'

const DiscoverMore = ({offerBanner}) => {
  return (
    <div className='lg:py-6 py-3 px-3 lg:px-1' >
    <div className='lg:px-12' >
      <h3 className='text-[1rem] lg:text-[1.5rem] py-0 font-[600]' >Discover More</h3>
      <div className='grid lg:grid-cols-2 gap-4 my-2 grid-flow-row' >
        {
          offerBanner && offerBanner.map((banner)=>(
            <Link to={banner.bannerLink} >
            <div className='col-span-1  ' >
              <img className='w-full rounded-[5px] lg:rounded-[10px]  ' src={banner.bannerImage} alt="" />
            </div>
            </Link>
          ))
        }
      </div>
      </div>
    </div>
  )
}

export default DiscoverMore