import React,{useState,useEffect} from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  
  Button,
} from "@material-tailwind/react";
import GoogleIcon from "../../asset/search.png"
import { PhoneIcon,EnvelopeIcon } from '@heroicons/react/24/outline';
import {useDispatch,useSelector} from "react-redux"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { db,auth } from "../../firebase.config";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { loginUsingPhone } from '../../actions/userActions';
import MobileNumberInput from './MobileNumberInput';
import { useCountries } from "use-react-countries";
import OTP from './OTP';
import Logo from "../../asset/logo.png"
import { InformationCircleIcon } from '@heroicons/react/20/solid';
const MobileandOTP = ({active,setActive,handleGoogleLogin,handleRegister}) => {


    const [phone, setPhone] = useState('')
    const [otp, setOtp] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
    const [mobileNumberPresent, setMobileNumberPresent] = useState(false)
    const [userUid, setUserUid] = useState('')
    const [showOTP, setShowOTP] = useState(false)
  const dispatch = useDispatch();
   const { countries } = useCountries();
  const [country, setCountry] = React.useState(221);
  const { name, flags, countryCallingCode } = countries[country];

   const onCaptchVerify = () => {
    if (!window.recaptchaVerify) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  };
  const onSignup = async () => {
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + countryCallingCode + phone;
    const mobileRef = collection(db, "user");
    const q = query(mobileRef, where("phone", "==", phone));
    const getMobileInfo = await getDocs(q);
    var phoneRegex = 10;
    // console.log(getMobileInfo);
      if(phone === "" || phone.length<10){
        setPhoneError(true)
        alert("Enter a Valid Phone Number")
        setPhoneErrorMessage("Enter a Valid Phone Number")
      }
      if(phone !== "" && getMobileInfo.docs.length === 0 ){
         setPhoneError(true)
        alert("Phone Number is not Present")
        setPhoneErrorMessage("Phone Number is not Present")
      }
    if (getMobileInfo.docs.length > 0) {
      setPhoneError(false)
   console.log(getMobileInfo.docs[0].id)   
   setUserUid(getMobileInfo.docs[0].id)
      setMobileNumberPresent(true);
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setShowOTP(true);
         
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setMobileNumberPresent(false);
      console.log("user not present");
    }
  };
  const resendOTP = () =>{
     onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
     const formatPh = "+" + countryCallingCode + phone;
    auth().signInWithPhoneNumber(formatPh, appVerifier, auth)
  }

  const handleMobileRegister = () =>{
    dispatch(loginUsingPhone(phone,otp,userUid))
  }
  console.log(phone,otp)
  return (
    <div className='px-2' >
   
      <Card shadow={false} className="lg:w-[500px] w-full py-3 px-6  ">
      <CardHeader
        variant="gradient"
        color='grey'
        shadow={false}
        floated={false}
        
        className="mb-0 grid h-20 place-items-center"
      >
        <img className='w-[80px]' src={Logo} alt="" />
        <Typography variant="h5" className="text-[#1287E3]" >
          Login to your Account
        </Typography>
      
      </CardHeader>
      
      <CardBody className="flex flex-col gap-4">
      <div className='flex items-center flex-col justify-center' >
       <Button
        size="lg"
        variant="outlined"
        onClick={handleGoogleLogin}
        color="blue-gray"
        className="flex w-[260px] my-2  items-center justify-center gap-6"
      >
        <img src={GoogleIcon} alt="google" className="h-4 w-4" />
      <p className='text-[0.7rem]' > Continue with Google</p>  
      </Button>
    <Button
        size="lg"
        variant="outlined"
        onClick={()=>setActive('email')}
        color="blue-gray"
        className="flex w-[260px]  items-center justify-center gap-6"
      >
      <EnvelopeIcon className='w-4 h-4' />
      <p className='text-[0.7rem]' > Continue with Email</p>
    
      </Button> 
     
      </div>
     {!showOTP ? 
     <div>
      <MobileNumberInput phoneError={phoneError} phoneErrorMessage={phoneErrorMessage} name={name} flags={flags} countryCallingCode={countryCallingCode} countries={countries} setCountry={setCountry} phone={phone} setPhone={setPhone} />
       {phoneError && <p className='text-red-500 text-[.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {phoneErrorMessage && phoneErrorMessage}</p>}
       </div>
       : <OTP otp={otp} setOtp={setOtp} />}
      {/* {showOTP && <p onClick={resendOTP} className='text-center my-3' >Resent OTP</p> } */}
      </CardBody>
      <CardFooter className="pt-0">
       {showOTP ?  <Button onClick={handleMobileRegister}  variant="solid" className='bg-[#1287E3]' fullWidth>
          Verify OTP
        </Button> : 
        <Button onClick={onSignup}  variant="solid" className='bg-[#1287E3]' fullWidth>
          Send OTP
        </Button>
        }
        <Typography variant="small" className="mt-6 flex justify-center">
        Do not have a account?
        <Link to="/register" >
          <Typography
            
            variant="small"
           
            className="ml-1 text-[#1287E3] font-bold"
          >
            Sign Up
          </Typography>
          </Link>
        </Typography>
      </CardFooter>
    </Card>
     <div id="recaptcha-container"></div>
    </div>
  )
}

export default MobileandOTP