import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  CardFooter
} from "@material-tailwind/react";
import {ShoppingCartIcon} from "@heroicons/react/24/outline"
import { Link } from 'react-router-dom';

const ProductCard = ({product}) => {
  return (
    <Link to={`/product/${product.id}`} >
    <div style={{fontFamily:"DM Sans"}} className='bg-white border-gray-200 border-[1px] w-[200px]  lg:w-[300px] rounded-[10px]   py-2                                                                                    ' >
      <div className='px-3'>
        <p className='font-[400] text- text-white bg-[#DE562C] absolute px-2 ml-0 py-1 text-[0.4rem] lg:text-[0.7rem] rounded-[5px] ' >Save ₹{product.realPrice - product.price}</p>
        <img className='rounded-[10px] ' src={product.coverImage && product.coverImage} alt="" />
      </div>
      <div className=' px-3 lg:px-6 mt-2' >
      <p className='text-center text-gray-600 text-[0.5rem] lg:text-[0.8rem] ' >{product.brand}</p>
        <h4 className='text-center  font-[500] text-[0.7rem] lg:text-[1.1rem] ' >Refurbished {product.productName && product.productName}</h4>
        <div className='flex mt-1 items-center justify-center px-2' >
          <p className='font-[600] text-[.9rem] lg:text-[1.2rem] mr-4 ' ><span className='font-[500]' style={{fontFamily:"Bebas Neue"}} >₹</span> {product.showPrice && product.showPrice}</p>
           <p className='font-[500] line-through text-gray-500 text-[0.5rem] lg:text-[0.9rem] ' >₹{product.realPrice}</p>
          
        </div>
        <div className='mt-2' >
          <button className='w-full bg-[#1287E3] px-3 py-2 flex lg:text-[1rem] text-[.7rem] items-center justify-center  rounded-[10px] text-white' ><ShoppingCartIcon className='lg:w-[20px] w-[15px] mr-2' />Add to Cart</button>
        </div>
       
      </div>
    </div>
    </Link>
  )
}

export default ProductCard