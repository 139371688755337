import React, { useState,useEffect } from 'react'
import CheckoutLeft from '../components/Checkout/CheckoutLeft'
import CheckoutRight from '../components/Checkout/CheckoutRight'
import { useSelector,useDispatch } from 'react-redux'
import { saveOrder } from '../actions/orderActions'
import { useNavigate } from 'react-router-dom'
import { removeCart } from '../actions/cartActions'
import { database } from "../firebase.config";
import { ref, set, push, onValue,remove } from "firebase/database";
const Checkout = () => {

     const { error, loading, isAuthenticated,user,userProfile } = useSelector(
    (state) => state.users
  );
  const {cartItems,shippingInfo} = useSelector(
        (state) => state.cart
    )
    
     const {order,orderCompleted} = useSelector(
        (state) => state.orders
    )
    const [addresses, setAddresses] = useState([])
      const today = new Date();
const yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;

const formattedToday = dd + '/' + mm + '/' + yyyy;
    const navigate = useNavigate()
     const [totalItemPrice, setTotalItemPrice] = useState(0)
    const dispatch = useDispatch();
    const [orderData, setOrderData] = useState({
        name:userProfile?.name ? userProfile.name : '',
        email:userProfile?.email ? userProfile?.email : '',
        alternameNumber:userProfile?.phone ? userProfile?.phone : '',
        addressLineOne:'',
        city:'',
        state:'',
        date:new Date(),
        pinCode:'',
        paymentStatus:'',
        orderStatus:"Purchased",
        totalPrice: 0 ,
        products:cartItems ? cartItems : [],
        userId:user ? user : '',
    
    })

    const handlePayment = () =>{
    
    
         dispatch(saveOrder(orderData,cartItems))
        dispatch(removeCart())
       
       navigate("/orderSuccess")
    }
     const fetchAddresses = () =>{
      const reviewCountRef = ref(database, 'addresses/' + user);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));

      setAddresses(newProducts)
      }
   


    },);
    }
    useEffect(() => {
      
      if(cartItems!==[]){
      
        cartItems.map((item)=>{
            setOrderData((prev)=>({
        ...orderData,
        totalPrice:(prev.totalPrice+Number(item.price))+(prev.totalPrice+Number(item.price)*0.18) 
      }))
          
        })
        
     }
      
   fetchAddresses()

 
     
    }, [cartItems])
    console.log(orderData.totalPrice,orderData)

  return (
    <div style={{ fontFamily: "DM Sans" }}>
        <div className='lg:grid  lg:grid-cols-5 grid-flow-col' >
            <CheckoutLeft handlePayment={handlePayment} orderData={orderData} addresses={addresses} cartItems={cartItems} userProfile={userProfile} setOrderData={setOrderData} />
            <CheckoutRight totalItemPrice={orderData.totalPrice} cartItems={cartItems} />
        </div>
    </div>
  )
}

export default Checkout