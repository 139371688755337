import React from 'react'
import {
    Drawer,
    Button,
    Typography,
    IconButton,
    Checkbox

} from "@material-tailwind/react";
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';

const Filter = ({ brandList, brandName, setBrandName, categoryName, categoryList, setCategoryName, min, setmin, max, setMax, fetchProduct, stock, setStock, ram, setram,condition,setCondition }) => {
    console.log(brandList)
    const [open, setOpen] = React.useState(false);

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    const handleBrandChange = (brand, e) => {

        console.log(brandName.includes(brand.name), brand.name)
        if (!brandName.includes(brand.name)) {
            setBrandName(() => [...brandName, brand.name])

        }
        else {
            setBrandName((prevState) => prevState.filter((prevItem) => prevItem !== brand.name))
        }
        setOpen(false)
        console.log(brandName)
    }
    const handleCategoryChange = (category) => {


        if (!categoryName.includes(category.value)) {
            setCategoryName(() => [...categoryName, category.value])

        }
        else {
            setCategoryName((prevState) => prevState.filter((prevItem) => prevItem !== category.value))
        }
        setOpen(false)

        console.log(brandName)
    }
    const handleRamChange = (data) => {
        if (!ram.includes(data)) {
            setram(() => [...ram, data])

        }
        else {
            setram((prevState) => prevState.filter((prevItem) => prevItem !== data))
        }
        setOpen(false)
    }
    const handleStockChange = (data) => {
        if (!stock.includes(data)) {
            setStock(() => [...stock, data])

        }
        else {
            setStock((prevState) => prevState.filter((prevItem) => prevItem !== data))
        }
        setOpen(false)
    }
    const handleConditionChange = (data) =>{
           if (!condition.includes(data)) {
            setCondition(() => [...stock, data])

        }
        else {
            setCondition((prevState) => prevState.filter((prevItem) => prevItem !== data))
        }
        setOpen(false)
    }
    return (
        <>
            <div style={{ fontStyle: "DM Sans" }} className='lg:col-span-1 hidden lg:block  lg:w-[90%] rounded-[15px] bg-white  py-3 lg:mr-6 ' >
                <div className=' flex items-center  border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 justify-between ' >
                    <h4 className='text-[1.5rem] font-[500] ' >Filter</h4>
                    <p onClick={() => {
                        setBrandName([])
                        setCategoryName([])
                        setmin(0)
                        setMax(100000)
                        setStock([])
                        setram([])
                        setCondition([])
                    }} className='text-[#E36529] cursor-pointer ' >Clear Filter</p>
                </div>
                <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                    <div className='flex items-center justify-between' >
                        <div>
                            <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Price</h4>
                        </div>
                        <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                            <p onClick={() => {
                                setmin(0)
                                setMax(100000)
                            }} className='text-[1rem] text-[#E36529]' >Clear</p>
                        </div>
                    </div>

                    <div className='mt-3 flex items-center justify-between ' >
                        <p>₹ </p>
                        <input value={min} onChange={(e) => { setmin(e.target.value) }} className='xl:w-[120px] lg:w-[80px] border-gray-400 border-[1px] outline-none px-3 py-2 rounded-[5px]' type="number" placeholder='min' />
                        <p>-</p>
                        <input value={max} onChange={(e) => { setMax(e.target.value) }} className='xl:w-[120px] lg:w-[80px] border-gray-400 border-[1px] outline-none px-3 py-2 rounded-[5px]' type="number" placeholder='max' />
                        <div>
                            <button onClick={fetchProduct} className='border-gray-400 border-[1px] px-4 rounded-lg py-1' >Go</button>
                        </div>
                    </div>
                </div>
                <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                    <div className='flex items-center justify-between' >
                        <div>
                            <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Choose a Brand</h4>
                        </div>
                        <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                            <p onClick={() => setBrandName([])} className='text-[1rem] text-[#E36529]' >Clear</p>
                        </div>
                    </div>
                    <div className='mt-3 ' >
                        {
                            brandList && brandList.map((brand, key) => (
                                <>
                                    {brand?.name && <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label={brand.name} onChange={() => handleBrandChange(brand)} className='' defaultChecked={brandName.includes(brand.name) ? true : false} />

                                    </div>}
                                </>
                            ))
                        }

                    </div>
                </div>
                <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                    <div className='flex items-center justify-between' >
                        <div>
                            <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Choose a RAM</h4>
                        </div>
                        <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                            <p onClick={() => setram([])} className='text-[1rem] text-[#E36529]' >Clear</p>
                        </div>
                    </div>
                    <div className='mt-3 ' >
                        <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="3 GB" onChange={() => handleRamChange("3 GB")} className='' defaultChecked={brandName.includes("3 GB") ? true : false} />

                        </div>
                        <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="4 GB" onChange={() => handleRamChange("4 GB")} className='' defaultChecked={brandName.includes("4 GB") ? true : false} />

                        </div>
                        <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="6 GB" onChange={() => handleRamChange("6 GB")} className='' defaultChecked={brandName.includes("6 GB") ? true : false} />

                        </div>
                        <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="8 GB" onChange={() => handleRamChange("8 GB")} className='' defaultChecked={brandName.includes("8 GB") ? true : false} />

                        </div>
                        <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="12 GB" onChange={() => handleRamChange("12 GB")} className='' defaultChecked={brandName.includes("12 GB") ? true : false} />

                        </div>


                    </div>
                </div>
                <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                    <div className='flex items-center justify-between' >
                        <div>
                            <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Choose a Category</h4>
                        </div>
                        <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                            <p onClick={() => setCategoryName([])} className='text-[1rem] text-[#E36529]' >Clear</p>
                        </div>
                    </div>
                    <div className='mt-3 ' >
                        {
                            categoryList && categoryList.map((category, key) => (
                                <>
                                    {category.name && <div key={key} className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label={category.name} onChange={() => handleCategoryChange(category)} className='' defaultChecked={categoryName.includes(category.value) ? true : false} />

                                    </div>}
                                </>
                            ))
                        }

                    </div>
                </div>
               
                <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                    <div className='flex items-center justify-between' >
                        <div>
                            <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Choose Condition</h4>
                        </div>
                        <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                            <p onClick={() => setCondition([])} className='text-[1rem] text-[#E36529]' >Clear</p>
                        </div>
                    </div>
                    <div className='mt-3 ' >

                        <div className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="Fair" onChange={() => handleConditionChange("fair")} className='' defaultChecked={condition.includes("fair") ? true : false} />

                        </div>
                        <div className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="Good" onChange={() => handleConditionChange("good")} className='' defaultChecked={condition.includes("good") ? true : false} />

                        </div>
                         <div className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="Superb" onChange={() => handleConditionChange("superb")} className='' defaultChecked={condition.includes("superb") ? true : false} />

                        </div>


                    </div>
                </div>



            </div>
            <div className='lg:hidden' >
                <React.Fragment>
                    <div className='flex items-center justify-end mr-2' >
                        <button className='flex items-center justify-center bg-gray-400 text-white px-4 py-2 rounded-[10px] ' onClick={openDrawer}><AdjustmentsHorizontalIcon className='w-[20px] mr-2' /> Filter</button>
                    </div>
                    <Drawer open={open} onClose={closeDrawer} className="p-4 overflow-scroll ">
                        <div className="mb-6 flex items-center justify-between">
                            <Typography style={{ fontStyle: "DM Sans" }} variant="h5" color="blue-gray">
                                Filter
                            </Typography>
                            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </IconButton>
                        </div>
                        <div style={{ fontStyle: "DM Sans" }} className='lg:col-span-1   lg:w-[90%] rounded-[15px] bg-white  py-3 lg:mr-6 ' >
                            <div className=' border-b-[1px] border-gray-200 px-2 py-3 ' >
                                <div className='flex items-center justify-between' >
                                    <div>
                                        <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Price</h4>
                                    </div>
                                    <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                                        <p onClick={() => {
                                            setmin(0)
                                            setMax(100000)
                                        }} className='text-[1rem] text-[#E36529]' >Clear</p>
                                    </div>
                                </div>
                                <div className='mt-3 flex items-center justify-between ' >
                                    <p>₹ </p>
                                    <input value={min} onChange={(e) => { setmin(e.target.value) }} className='w-[70px] border-gray-400 border-[1px] outline-none px-3 py-2 rounded-[5px]' type="number" placeholder='min' />
                                    <p>-</p>
                                    <input value={max} onChange={(e) => { setMax(e.target.value) }} className='w-[70px] border-gray-400 border-[1px] outline-none px-3 py-2 rounded-[5px]' type="number" placeholder='max' />
                                    <div>
                                        <button onClick={() => {
                                            setOpen(false)
                                            fetchProduct()
                                        }} className='border-gray-400 border-[1px] px-3 rounded-lg py-1' >Go</button>
                                    </div>
                                </div>
                            </div>

                            <div className=' border-b-[1px] border-gray-200 px-3 py-3 ' >
                                <div className='flex items-center justify-between' >
                                    <div>
                                        <h4 className='text-[1.1rem] font-[500]' >Choose a Brand</h4>
                                    </div>
                                    <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                                        <p onClick={() => setBrandName([])} className='text-[.7rem] text-[#E36529]' >Clear</p>
                                    </div>
                                </div>
                                <div className='mt-3 ' >
                                    {
                                        brandList && brandList.map((brand, key) => (
                                            <>
                                                {brand?.name && <div className='font-500 rounded-lg   px-0 py-0 flex items-center justify-start cursor-pointer ' >
                                                    <Checkbox label={brand.name} onChange={() => handleBrandChange(brand)} className='' defaultChecked={brandName.includes(brand.name) ? true : false} />

                                                </div>}
                                            </>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                                <div className='flex items-center justify-between' >
                                    <div>
                                        <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Choose a RAM</h4>
                                    </div>
                                    <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                                        <p onClick={() => setram([])} className='text-[1rem] text-[#E36529]' >Clear</p>
                                    </div>
                                </div>
                                <div className='mt-3 ' >
                                    <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label="3 GB" onChange={() => handleRamChange("3 GB")} className='' defaultChecked={brandName.includes("3 GB") ? true : false} />

                                    </div>
                                    <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label="4 GB" onChange={() => handleRamChange("4 GB")} className='' defaultChecked={brandName.includes("4 GB") ? true : false} />

                                    </div>
                                    <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label="6 GB" onChange={() => handleRamChange("6 GB")} className='' defaultChecked={brandName.includes("6 GB") ? true : false} />

                                    </div>
                                    <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label="8 GB" onChange={() => handleRamChange("8 GB")} className='' defaultChecked={brandName.includes("8 GB") ? true : false} />

                                    </div>
                                    <div className='font-500 rounded-lg   xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                                        <Checkbox label="12 GB" onChange={() => handleRamChange("12 GB")} className='' defaultChecked={brandName.includes("12 GB") ? true : false} />

                                    </div>


                                </div>
                            </div>
                            <div className=' border-b-[1px] border-gray-200 px-3 py-3 ' >
                                <div className='flex items-center justify-between' >
                                    <div>
                                        <h4 className='text-[1.1rem] font-[500]' >Choose a Category</h4>
                                    </div>
                                    <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                                        <p onClick={() => setCategoryName([])} className='text-[.7rem] text-[#E36529]' >Clear</p>
                                    </div>
                                </div>
                                <div className='mt-3 ' >
                                    {
                                        categoryList && categoryList.map((category, key) => (
                                            <>
                                                {category?.name && <div className='font-500 rounded-lg  px-0 py-0 flex items-center justify-start cursor-pointer ' >
                                                    <Checkbox label={category.name} onChange={() => handleCategoryChange(category)} className='' defaultChecked={false} />

                                                </div>}
                                            </>
                                        ))
                                    }

                                </div>
                            </div>
                           
                               <div className=' border-b-[1px] border-gray-200 px-3 xl:px-6 py-3 ' >
                    <div className='flex items-center justify-between' >
                        <div>
                            <h4 className='xl:text-[1.5rem] lg:text-[1.1rem] font-[500]' >Choose Condition</h4>
                        </div>
                        <div className='font-500 rounded-lg  hover:bg-gray-100 px-2 py-2 cursor-pointer ' >

                            <p onClick={() => setCondition([])} className='text-[1rem] text-[#E36529]' >Clear</p>
                        </div>
                    </div>
                    <div className='mt-3 ' >

                        <div className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="Fair" onChange={() => handleConditionChange("fair")} className='' defaultChecked={condition.includes("fair") ? true : false} />

                        </div>
                        <div className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="Good" onChange={() => handleConditionChange("good")} className='' defaultChecked={condition.includes("good") ? true : false} />

                        </div>
                         <div className='font-500 rounded-lg  xl:px-2 xl:py-2 lg:px-0 lg:py-0 flex items-center justify-start cursor-pointer ' >
                            <Checkbox label="Superb" onChange={() => handleConditionChange("superb")} className='' defaultChecked={condition.includes("superb") ? true : false} />

                        </div>


                    </div>
                </div>




                        </div>

                    </Drawer>
                </React.Fragment>
            </div>
        </>
    )
}

export default Filter