import React from 'react'
import Partners from "../../asset/steps/partners.png"
import Pincode from "../../asset/steps/pincode.png"
import Sold from "../../asset/steps/sold.png"
import Eco from "../../asset/steps/eco.png"
const ShoppingData = () => {
  return (
    <div className='bg-[#121212] px-2 py-3 lg:py-8 my-3  mx-2 lg:mx-12 rounded-[5px] lg:rounded-[10px]' >
        <div className='grid grid-cols-4 grid-flow-col gap-1 lg:gap-4 items-center  ' >
            <div className='border-r-[1px] flex items-center justify-center border-gray-300' >
            <div className='lg:mr-4 mr-1' >
                <img className='xl:w-[60px] lg:w-[60px] w-[25px]' src={Partners} alt="" />
                </div>
                <div>
                <p className='font-[600] text-white xl:text-[1.2rem] lg:text-[1rem] text-[.4rem]' >700+</p>
                <p className='xl:text-[1rem] text-gray-300 lg:text-[.8rem] text-[.3rem] ' >Partner Retailers </p>
                </div>
            </div>
               <div className='border-r-[1px] flex items-center justify-center border-gray-300' >
            <div className='lg:mr-4 mr-1' >
                <img className='xl:w-[60px] lg:w-[60px] w-[25px]' src={Pincode} alt="" />
                </div>
                <div>
                <p className='font-[600] text-white xl:text-[1.2rem] lg:text-[1rem] text-[.4rem]'>175+</p>
                <p className='xl:text-[1rem] text-gray-300 lg:text-[.8rem] text-[.3rem] '>Pincodes Available </p>
                </div>
            </div>
               <div className='border-r-[1px] flex items-center justify-center border-gray-300' >
            <div className='lg:mr-4 mr-1' >
                <img className='xl:w-[60px] lg:w-[60px] w-[25px]' src={Sold} alt="" />
                </div>
                <div>
                <p className='font-[600] text-white xl:text-[1.2rem] lg:text-[1rem] text-[.4rem]'>1500+</p>
                <p className='xl:text-[1rem] text-gray-300 lg:text-[.8rem] text-[.3rem] '>Number of Products Sold </p>
                </div>
            </div>
               <div className=' flex items-center justify-center border-gray-300' >
            <div className='lg:mr-4 mr-1' >
                <img className='xl:w-[60px] lg:w-[60px] w-[25px]' src={Eco} alt="" />
                </div>
                <div>
                <p className='font-[600] text-white xl:text-[1.2rem] lg:text-[1rem] text-[.4rem]'>200+</p>
                <p className='xl:text-[1rem] text-gray-300 lg:text-[.8rem] text-[.3rem] '>Tons of E-Waste Saved  </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ShoppingData