
import './App.css';
import {Routes, Route} from 'react-router-dom'
import Home from './pages/Home';
import ShopByBrand from './pages/ShopByBrand';
import ProductList from './pages/ProductList';
import About from './pages/About';
import Contact from './pages/Contact';
import SingleProduct from './pages/SingleProduct';
import Register from './pages/Register';
import Login from './pages/Login';
import Cart from './pages/Cart';
import Profile from './pages/Profile';
import Checkout from './pages/Checkout';
import OrderSuccess from './pages/OrderSuccess';
import MyOrders from './pages/MyOrders';
import ViewOrder from './pages/ViewOrder';
import ShopByCategory from './pages/ShopByCategory';
import ForgotPassword from './pages/ForgotPassword';


function App() {
  return (
    <div className="App">
     <Routes>
        <Route exact path="/" element={<Home/>}   />
        <Route exact path="/register" element={<Register />}   />
        <Route exact path="/login" element={<Login />}   />
        <Route exact path="/shop/brand" element={<ShopByBrand />}   />
        <Route exact path="/shop/brand/:brand" element={<ProductList />}   />
           <Route exact path="/shopAll" element={<ProductList />}   />
            <Route exact path="/shop/:category" element={<ProductList />}   />
            <Route exact path="/shop/price/:price" element={<ProductList />}   />
              <Route exact path="/shop/type/:type" element={<ProductList />}   />
          <Route exact path="/product/:id" element={<SingleProduct />}   />
        <Route exact path="/about" element={<About />}   />
         <Route exact path="/cart" element={<Cart />}   />
          <Route exact path="/profile" element={<Profile />}   />
        <Route exact path="/contact" element={<Contact />}   />
        <Route exact path="/checkout" element={<Checkout />}   />
        <Route exact path="/orderSuccess" element={<OrderSuccess />}   />
        <Route exact path="/myOrders" element={<MyOrders />}   />
           <Route exact path="/forgotPassword" element={<ForgotPassword />}   />
        <Route exact path="/orderDetails/:id" element={<ViewOrder />}   />
        <Route exact path="/shop/category" element={<ShopByCategory />}   />

     </Routes>
    </div>
  );
}

export default App;
