import React from 'react'

const CheckoutRight = ({cartItems,totalItemPrice}) => {
  return (
    <div className='col-span-2 border-l-[1px] lg:px-12 px-3 py-8 border-gray-300 bg-[#e9f3f9]' >
        <h4 className="font-[600] text-lg py-5">Your Order</h4>
        <div>
        {
            cartItems && cartItems.map((item)=>(
                <div className='flex items-center bg-white rounded-lg px-6 py-4 justify-start' >
                    <div>
                        <img className='lg:w-[120px] w-[70px] rounded-lg' src={item.coverImage} alt="" />
                    </div>
                    <div className='ml-4 ' >
                        <p className='lg:text-[18px] text-[14px] ' >{item.productName && item.productName}</p>
                        <p className='lg:text-[24px] text-[16px] font-[600] mt-2  '>Rs. {item.price}/-</p>
                    </div>
                </div>
            ))
        }
        </div>
        <div className='flex mx-2 items-center my-3 justify-between' >
        <p className='text-[1.4rem] font-[600] ' >Total Price</p>
        <p className='text-[1.4rem] font-[600] text-green-600 gont' >Rs.{totalItemPrice} <span className='text-gray-400 text-[.9rem]' >(incl. GST)</span> </p>
        </div>
        <div >
          
        </div>
    </div>
  )
}

export default CheckoutRight