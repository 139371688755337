import React from 'react'
import BannerImage from "../../asset/banner1.png"
import { Link } from 'react-router-dom'
const HomeBanner = ({banner}) => {
    
  return (
    <Link to={banner.bannerLink} >
    <div style={{fontFamily:"DM Sans"}} >
    
         { banner !== '' ?  <div className='bg-gray-200 2xl:h-[440px] h-[110px] md:h-[120px] lg:h-[320px] xl:h-[430px] ' >
                <img className=' w-full  ' src={banner.bannerImage} alt="" />
            </div> :  
            (
              <div className='bg-gray-200 h-[450px]  '>

              </div>
            )
            }
    </div>
    </Link>
  )
}

export default HomeBanner