import React from 'react'
import OtpInput from 'react-otp-input';
import "./LoginStyle.css"
const OTP = ({otp,setOtp}) => {
  return (
    <div className='my-6' >
        <OtpInput
      value={otp}
      onChange={setOtp}
      inputStyle="inputStyle"
      numInputs={6}
      inputType='number'
      renderSeparator={<span></span>}
      renderInput={(props) => <input {...props} />}
    />

    </div>
  )
}

export default OTP