import React from 'react'
import Apple from "../../asset/brandlogo/apple.png"
import Acer from "../../asset/brandlogo/acer.png"
import Samsung from "../../asset/brandlogo/samsung.png"
import Realme from "../../asset/brandlogo/realme.png"
import Oppo from "../../asset/brandlogo/oppo.png"
import Vivo from "../../asset/brandlogo/vivo.png"
import Oneplus from "../../asset/brandlogo/oneplus.png"
import { Link } from 'react-router-dom'
const BrandList = ({brandList}) => {
    
  return (
    <div style={{ fontFamily: "DM Sans" }} className='lg:my-24 my-2 mx-3 lg:mx-16 ' >
        <div >
           
            <div className='flex items-center justify-center flex-wrap py-4 px-4 lg:py-12 lg:px-16 ' >
            {brandList && brandList.map((brand)=>(
              <>
                    {brand.name &&  <Link to={"/shop/brand/"+brand.name} >
                <div className='  lg:mx-3 mx-2 my-2 lg:my-3  ' >
                    <img className='lg:w-[150px] w-[70px] rounded-[10px] ' src={brand.image} alt="" />
                </div>
                </Link>}
                </>
            ))}
          
              
            </div>
             <h1 className='font-[700] text-[1.4rem] lg:text-[2.5rem] text-center ' >Choose the best brand for yourself</h1>
            <p className='text-center text-[0.6rem] lg:text-[1rem]  text-gray-600' >Each and every one of our mobile devices undergoes an extensive evaluation process consisting of a rigorous check across a total of 60 essential parameters like 
<br />
- All Sensors, Speakers, Display, Touchscreen, Battery Life , Front Camera, Rear Camera, Charging Port and more, 
<br/>
ensuring that only the highest quality products are delivered to you
</p>
        </div>
    </div>
  )
}

export default BrandList