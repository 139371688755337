import React,{useState,useEffect} from 'react'
import Navbar from '../components/Layout/Navbar'
import { useSelector,useDispatch } from 'react-redux'
import CartList from '../components/Cart/CartList'
import Footer from '../components/Layout/Footer'
import { removeItemsFromCart } from '../actions/cartActions'
import CartPrice from '../components/Cart/CartPrice'
import { useNavigate } from 'react-router-dom'
const Cart = () => {
    const {cartItems,shippingInfo} = useSelector(
        (state) => state.cart
    )
    const { error, loading, isAuthenticated, user, userProfile } = useSelector(
    (state) => state.users
  );
    const [totalItemPrice, setTotalItemPrice] = useState(0)
    const [totalRealItemPrice, setTotalRealItemPrice] = useState(0)
    const navigate = useNavigate()
     const dispatch = useDispatch();
     
     useEffect(() => {
     if(cartItems!==[]){
        cartItems.map((item)=>{
            setTotalItemPrice((prev)=>(prev+Number(item.price)))
            setTotalRealItemPrice((prev)=>(prev+Number(item.realPrice)))
        })
        
     }
     if(!isAuthenticated){
        navigate("/login")
     }
     
     }, [])
    console.log(totalItemPrice,totalRealItemPrice)
  return (
    <div style={{fontStyle:"DM Sans"}} className='bg-[#e9f3f9]  '>
        <Navbar />
            <div className='xl:grid xl:grid-cols-4 grid-flow-col px-4 py-3 xl:px-8 xl:py-6 ' >
                <CartList setTotalRealItemPrice={setTotalRealItemPrice} totalItemPrice={totalItemPrice} setTotalItemPrice={setTotalItemPrice} cartItems={cartItems} />
                <CartPrice totalRealItemPrice={totalRealItemPrice} totalItemPrice={totalItemPrice} cartItems={cartItems} />
            </div>
            <Footer />
    </div>
  )
}

export default Cart