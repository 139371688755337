import React,{useState} from 'react'
import IphoneImageOne from "../../asset/iphoneblackOne.png"
import IphoneImageTwo from "../../asset/iphoneblacktwo.png"
import IphoneImageThree from "../../asset/iphoneblackthree.png"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Thumbs,
  FreeMode,
 
} from "swiper";
import 'swiper/css/navigation';
// Import Swiper styles
import 'swiper/css/thumbs';
import "swiper/css";
import 'swiper/css/pagination';
const ProductImage = ({product}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <div className='lg:col-span-2  ' >
    <div className=' ' >
        <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          slidesPerView={1}
          
          
          modules={[Autoplay,Thumbs,Pagination,Navigation]}
          style={{
            '--swiper-pagination-progressbar-bg-color': '#DE562C',
            '--swiper-pagination-color': 'black',
             '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
       
          }}
          pagination={true}
          navigation={true}
          >
            {
              product?.image && product?.image.map((asset)=>(
                  <SwiperSlide>
                <div className='  flex items-center justify-center  ' >
                    <img className='xl:w-[400px] w-[100%] rounded-[30px]' src={asset} alt="" />
                    </div>
                </SwiperSlide>
              ))
            }
              
      
        </Swiper>
        
        </div>
    </div>
  )
}

export default ProductImage