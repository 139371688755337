import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
const Faqs = () => {
    const faqs=[
        {
            question:"What is Refurbishing?",
            answers:"The refurbishing of mobile phones refers to the process of restoring used or pre-owned devices to a like-new or fully functional condition. It involves comprehensive cleaning, repair or replacement of any faulty or damaged components, software updates, and rigorous testing to ensure the device meets quality standards. At Reviwo, the devices are inspected, repaired, and tested by skilled professionals to ensure they function properly and are ready for resale. This process extends the lifespan of mobile phones, reduces electronic waste, and offers cost-effective options for consumers looking to purchase reliable device"
        },
        {
             question:"What is the warranty period for refurbished phones?",
            answers:"We offer a [insert warranty period] warranty on all our refurbished phones. This covers any potential defects or malfunctions that may arise during the specified warranty period."
        },
         {
             question:"Do refurbished phones come with accessories?",
            answers:"Yes, all our refurbished phones are sold with essential accessories such as charging cables and wall adapters. However, please note that specific accessories may vary depending on the availability and model of the refurbished phone."
        },
         {
             question:"Are the refurbished phones unlocked and compatible with all carriers?",
            answers:" Yes, we provide unlocked refurbished phones that are compatible with major carriers. You can use them with your preferred carrier by simply inserting your SIM card."
        },
         {
             question:"What is the refurbishment process for the phones?",
            answers:" Our refurbishment process includes thorough cleaning, hardware inspection, replacement of faulty parts with genuine components, software updates, and extensive testing to ensure optimal performance"
        },
        //    {
        //      question:"Can I return or exchange a refurbished phone?",
        //     answers:" We offer a return and exchange policy within [insert number of days] days of purchase, provided the device remains in the same condition as when it was sold and is accompanied by the original packaging and accessories."
        // },
        //  {
        //      question:"How do I place an order for a refurbished phone? ",
        //     answers:"Ordering a refurbished phone is simple. Just browse our website, select the desired model, add it to your cart, and proceed to checkout. Follow the instructions to complete the purchase securely."
        // },
        //  {
        //      question:"What payment options are available? ",
        //     answers:"We accept various payment options, including major credit cards, debit cards, and secure online payment platforms. You can choose the most convenient option during the checkout process."
        // },
        // {
        //      question:"Can I sell my old phone to Reviwo?  ",
        //     answers:"Yes, we offer a phone buyback program where you can sell your old devices to us. Simply visit our website, provide the necessary details about your phone, and receive a quote. We make the selling process quick and hassle-free."
        // },
        // {
        //      question:"How can I contact customer support for further assistance?  ",
        //     answers:"For any inquiries or assistance, you can reach our customer support team through [provide contact details], and our dedicated representatives will be happy to help you with your queries."
        // },
    ]
  return (
    <div style={{fontFamily:"DM Sans"}} className='lg:py-6 py-6 px-3 lg:px-1'> 
        
        <div className='bg-white lg:px-12 py-2 rounded-[16px] my-6' >
             {
            faqs && faqs.map((faq,key)=>(
                  <Disclosure className="" >
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full my-3 font-[Neue Haas Grotesk Display Pro] justify-between  bg-white border-b-[1px] border-[#d0d0d0] px-3  xl:px-6 xl:py-4 py-2 text-left text-[14px] xl:text-lg font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span className=''>{faq.question}</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-6 text-gray-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-lg text-gray-500">
                {faq.answers}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
            ))
        }
        </div>
    </div>
  )
}

export default Faqs