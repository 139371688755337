import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import Premium from "../../asset/premiumCategory.png"
import NewArrival from "../../asset/newArrivalsCategory.png"
import CameraPhone from "../../asset/bestcameraCategory.png"
import SellingPhone from "../../asset/sellingfastCategory.png"
import TrendingPhone from "../../asset/trendingCategory.png"
import {Link} from "react-router-dom"
const ShopByCategory = () => {
  return (
    <div style={{fontFamily:"DM Sans"}} className='lg:py-6 py-2 px-3 lg:px-1' >
     <div className='lg:px-12'>
    <h4 className='font-[600]  text-[1rem] lg:text-[1.5rem] '>Shop By Category</h4>
        <div className='mt-3' >
                 <Swiper   spaceBetween={4}
          className=""
          draggable={true}
          breakpoints={{
        300: {
          width: 300,
          slidesPerView: 1.6,
        },
        780: {
          width: 780,
          slidesPerView: 2,
        },
        1000:{
          width:1000,
          slidesPerView:3.1
        },
         1440:{
          width:1440,
          slidesPerView:3.7
        }
      }}
          autoplay={{
            delay: 2000,
            disableOnInteraction:false,
          }}
           navigation={true}
                     style={{
         '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
          '--swiper-pagination-color': '#DE562C',
        }}
       
          loop={false}
          modules={[Autoplay,Navigation]}
          >
            <SwiperSlide>
                <Link to="/shop/Premium">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[200px] mr-3 lg:rounded-[10px] rounded-[5px]' src={Premium} alt="" />
        </Link>
            </SwiperSlide>
              <SwiperSlide>
                <Link to="/shop/CameraPhone">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[200px] mr-3 lg:rounded-[10px] rounded-[5px]' src={CameraPhone} alt="" />
        </Link>
            </SwiperSlide>
               <SwiperSlide>
                <Link to="/shop/SellingFast">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[200px] mr-3 lg:rounded-[10px] rounded-[5px]' src={SellingPhone} alt="" />
        </Link>
            </SwiperSlide>
              <SwiperSlide>
                <Link to="/shop/NewArrival">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[200px] mr-3 lg:rounded-[10px] rounded-[5px]' src={NewArrival} alt="" />
        </Link>
            </SwiperSlide>
             <SwiperSlide>
                <Link to="/shop/TrendingPhone">
        <img className='xl:w-[300px]  2xl:w-[350px] lg:w-[200px] w-[200px] mr-3 lg:rounded-[10px] rounded-[5px]' src={TrendingPhone} alt="" />
        </Link>
            </SwiperSlide>
            
            
          </Swiper>
        </div>
        </div>
    </div>
  )
}

export default ShopByCategory