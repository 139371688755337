import React from 'react'
import { UserIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { ref, set, push } from "firebase/database";
import { Rating, Progress } from "@material-tailwind/react"
import { database } from "../../firebase.config";
import "./Product.css"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import AddReviewModal from './AddReviewModal';
const Review = ({userProfile,reviews,id,ratings,isAuthenticated}) => {
    const [reviewContent, setReviewContent] = useState('')
    const [stars, setStars] = useState(0)
    const [open, setOpen] = React.useState(false);
    console.log(ratings)
    const handleRating = (rate) => {
    setStars(rate)
  }
   const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value ,index) => console.log(value, index)

    const handleSubmit = ()=>{
          if(userProfile){
        const productListRef = ref(database, "reviews/" + id);
    const newProductRef = push(productListRef);
    set(newProductRef, {
      review:reviewContent,
      name:userProfile?.name,
      rating:stars,
      id:id
    }).then(()=>{
    setReviewContent('')
    setOpen(false)
    })
      }
    }

    console.log(stars)
  return (
    <div className='xl:px-8 px-6 py-12' >
        <div>
            <h4 className='font-[500] text-[1.4rem] ' >Add a Review:</h4>
            {/* <div className='mt-4' >
                
            </div> */}
            <div className='border-[1px] mt-2 border-gray-200 lg:px-4 px-2 py-3 rounded-[10px]' >
              <div className='lg:grid grid-cols-5 grid-flow-col items-center ' >
              <div className=' lg:border-r-[1px] col-span-1 mx-2 border-gray-400 ' >
              <div className='border-[1px] w-full lg:w-[150px]  xl:w-[200px] rounded-[10px]  border-green-400 ' >
              <div className=' rounded-[10px]   flex items-center justify-center px-3 py-2 lg:py-6 ' >
                <p className='text-green-400 lg:text-[1.8rem] font-[600]' >{reviews ? reviews.length : 0} <span className='text-gray-400 font-[400] text-[0.9rem]' >(reviews)</span></p>
              </div>
              <div>
              </div>
              </div>
              </div>
              
              <div className='  col-span-4 lg:mx-6 my-3 lg:my-0  '>
                <div className='flex items-center flex-col lg:flex-row justify-center' >
                <div>
                  <p className='font-[600] text-[1rem] lg:text-[1.5rem]' >Review this Product</p>
                  <p>Share your thoughts with other customers</p>
                  </div>
                 <AddReviewModal isAuthenticated={isAuthenticated} open={open} setOpen={setOpen} handleRating={handleRating} handleSubmit={handleSubmit} stars={stars} setStars={setStars} reviewContent={reviewContent} setReviewContent={setReviewContent} />
                </div>
              </div>
              </div>
              <div>
                  {
                reviews !==[] ? (reviews.map((review)=>(
                 
                <div className='w-full  my-2 bg-white border-b-[1px] border-gray-200 rounded-[20px] px-0 py-3 xl:py-6 flex items-start flex-col justify-start ' >
                <div className='flex items-center justify-start ml-2' >
                   
                       <h4 className='xl:text-[1.3rem] text-[1rem] font-[600] text-center ' >{review.name}</h4>
                       </div>
                    <div>
                     <div className='text-center my-1 ml-2' >
                        <Rating value={review.rating} readonly />
                        </div>
                        <p className='text-gray-600 my-1 lg:my-2 text-[0.7rem] xl:text-[1rem] ml-2 ' >{review.review}</p>
                        
                    </div>
                </div>
              
                ))):
                (
                    <div className='text-center bg-red-500 text-black' >
                    <h4 className='text-center bg-red text-black' >No Reviews Yet. Be the first one to write</h4>
                    </div>
                )
            } 
              </div>
            </div>
          
        </div>
    </div>
  )
}

export default Review