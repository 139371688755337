import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Logo from "../../asset/logo.png"
import {Link} from "react-router-dom"
import Logo2 from "../../asset/logo.png"
import {
  
  Bars3Icon,
 
  XMarkIcon,
  InformationCircleIcon,
  HomeIcon,
  UserGroupIcon,
  HomeModernIcon,
  UserCircleIcon,
  ShoppingCartIcon,
  ShoppingBagIcon,
  DevicePhoneMobileIcon,
  TagIcon,
  ArrowLeftOnRectangleIcon,
  LockOpenIcon,
  LockClosedIcon,
  ArrowRightOnRectangleIcon,
  
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useDispatch,useSelector } from 'react-redux'
import { Menu } from '@headlessui/react'
import { logout } from '../../actions/userActions'
import { Badge } from '@material-tailwind/react'
const mobileNav = [
  {
    name: 'Android',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '/shop/type/android',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'IOS',
    description: "Connect with third-party tools that you're already using.",
    href: '/shop/type/ios',
    icon: DevicePhoneMobileIcon,
  },
   {
    name: 'Shop By Brand',
    description: "Connect with third-party tools that you're already using.",
    href: '/shop/brand',
    icon: DevicePhoneMobileIcon,
  },
  {
    name: 'Shop By Category',
    description: "Connect with third-party tools that you're already using.",
    href: '/shop/category',
    icon: TagIcon,
  },
  {
    name: 'Shop All',
    description: "Connect with third-party tools that you're already using.",
    href: '/shopAll',
    icon: ShoppingCartIcon,
  },
  {
    name: 'About',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '/about',
    icon: InformationCircleIcon,
  },
  
  {
    name: 'Contact',
    description: "Connect with third-party tools that you're already using.",
    href: '/contact',
    icon: UserGroupIcon,
  },
  

  
]

const accountDetails = [
  {
     name: 'My Profile',
    description: "Connect with third-party tools that you're already using.",
    href: '/profile',
    icon: UserCircleIcon,
  },
  {
     name: 'My Orders',
    description: "Connect with third-party tools that you're already using.",
    href: '/myOrders',
    icon: ShoppingBagIcon,
  },
  
]

const loginDetails = [
  {
     name: 'Login',
    description: "If Already a User Login to Shop Now",
    href: '/login',
    icon: ArrowLeftOnRectangleIcon,
  },
  {
     name: 'Sign Up',
    description: "Create a New Accont if new user.",
    href: '/register',
    icon: LockClosedIcon,
  },
  
]



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
const { error, loading, isAuthenticated,user,userProfile } = useSelector(
    (state) => state.users
  );
  const { cartItems,shippingInfo } = useSelector(
    (state) => state.cart
  );
//   const { userProfile } = useSelector(
//     (state) => state.userProfile
//   );

  // console.log(userProfile)
    const dispatch = useDispatch()

    const onLogout =  () => {
      dispatch(logout())
    }
  //   const logout = async (event) => {
  //      event.currentTarget.disabled = true;
  //   console.log('button clicked');
   
  //     dispatch(logout());
  // };

  return (
    <Popover className="relative bg-[#ffffff] text-black z-[900]">
      <div className="mx-0 max-w-full py-2 px-6">
        <div className="flex items-center justify-between lg:ml-12  py-3 md:justify-start md:space-x">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Your Company</span>
              <img
                className="lg:w-[130px] w-[110px] "
                src={Logo2}
                alt=""
              />
            </Link>
          </div>
          
         
    
          <div className="-my-2 flex items-center justify-start -mr-2 md:hidden">
          <div className='mr-3' >
            <Link to="/cart">
            <div>
            <Badge content={cartItems.length}>
              <ShoppingCartIcon className='w-[30px] text-[#DE562C]' />
              </Badge>
            </div>
            
            </Link>
            </div>
            <Popover.Button className="inline-flex items-center justify-center rounded-md  p-2 text-[#489DCC]  hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">

             <Link to="/shop/brand" style={{fontStyle:"DM Sans"}} className="text-[16px] mr-4 font-[500] tracking-widest  text-[#121212] hover:text-[#59A4FF]">
       Shop By Brand
            </Link>
             <Link to="/shop/type/ios" style={{fontStyle:"DM Sans"}} className="text-[16px] mr-4 font-[500] tracking-widest  text-[#121212] hover:text-[#59A4FF]">
       IOS
            </Link>
                <Link to="/shop/type/android" style={{fontStyle:"DM Sans"}} className="text-[16px] mr-4 font-[500] tracking-widest  text-[#121212] hover:text-[#59A4FF]">
    Android
            </Link>
             <Link to="/shop/category" style={{fontStyle:"DM Sans"}} className="text-[16px] mr-4 font-[500] tracking-widest  text-[#121212] hover:text-[#59A4FF]">
          Shop By Category
            </Link>
         <Popover className="">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-transparent px-3 py-0 text-base font-medium text-[#121212]  hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
          More
          
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-[70%] z-10 mt-3 w-[180px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className=" bg-white p-7 ">
                    <Link
                        to="/shopAll"
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="ml-1 my-4">
                          <p className="text-sm font-medium text-gray-900">
                            Shop All
                          </p>
                          
                        </div>
                        
                      </Link>
                      <Link
                        to="/about"
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="ml-1 my-4">
                          <p className="text-sm font-medium text-gray-900">
                            About
                          </p>
                          
                        </div>
                        
                      </Link>
                   <Link
                        to="/contact"
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="ml-1 my-4">
                          <p className="text-sm font-medium text-gray-900">
                            Contact
                          </p>
                          
                        </div>
                        
                      </Link>
                    
                  </div>
                 
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
                       
           
             

         
          </Popover.Group>
        {/* {  <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <Link
             to="/register"  
              
              className="ml-8 text-[12px] xl:px-[29px] xl:py-[18px] xl:w-[180px] xl:h-[40px] inline-flex items-center justify-center whitespace-nowrap rounded-[50px] border-[#2E3191] border-[1px] text-[#2E3191] hover:bg-[#dfedfd] px-4 py-2  text-lg font-medium  shadow-sm hover:bg-gradient-to-r "
            >
              Create a Account
            </Link>
            <Link
             to="/login"  
              
              className="ml-8 text-[12px] xl:px-[29px] xl:py-[18px] xl:w-[120px] xl:h-[40px] inline-flex items-center justify-center whitespace-nowrap rounded-[50px] border border-transparent bg-[#2E3191] px-4 py-2  text-lg font-medium text-[#ffffff] shadow-sm hover:bg-gradient-to-r from-[#ee0979] to-[#ff6a00]"
            >
              Login
            </Link>
          </div>} */}
         {isAuthenticated ? (
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
          <Link to="/cart">
            <div>
            <Badge content={cartItems.length}>
              <ShoppingCartIcon className='w-[30px] text-[#DE562C]' />
              </Badge>
            </div>
            </Link>
         <div className="w-1/2  px-4">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-transparent px-6 py-0 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
          
           <span className='text-[#DE562C]' ><UserCircleIcon className='w-[35px]' /></span>
          
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-[180px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className=" bg-white p-7 ">
                    {accountDetails.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex h-8 w-8 shrink-0 items-center justify-center text-[#DE562C] sm:h-12 sm:w-12">
                           <item.icon
                                className="flex-shrink-0 h-8 w-8 mr-2 text-sky-500"
                                aria-hidden="true"
                              />
                        </div>
                        <div className="ml-1">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                          
                        </div>
                      </Link>
                    ))}
                    <div className='w-full bg-[#DE562C] rounded-[5px] text-center mt-4 mb-2 text-white  ' >
                      <button onClick={onLogout}  className='outline-none px-2 py-2' >Logout</button>
                    </div>
                  </div>
                 
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
            
          </div>
         ) 
         
          :  ( <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
          <Link to="/cart">
            <div>
            <Badge content={cartItems.length}>
              <ShoppingCartIcon className='w-[30px] text-[#DE562C]' />
              </Badge>
            </div>
            </Link>
         <div className="w-1/2  px-4">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-transparent px-3 py-0 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
          <span className='text-[#DE562C]' ><UserCircleIcon className='w-[35px]' /></span>

          
              <ChevronDownIcon
                className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-[180px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className=" bg-white p-7 ">
                    {loginDetails.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 flex items-center rounded-lg p-1 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex h-8 w-8 shrink-0 items-center justify-center text-[#DE562C] sm:h-12 sm:w-12">
                           <item.icon
                                className="flex-shrink-0 h-6 w-6 mr-2 text-sky-500"
                                aria-hidden="true"
                              />
                        </div>
                        <div className="ml-1">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                         
                          
                        </div>
                      </Link>
                    ))}
                  
                  </div>
                 
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
            
          </div>)}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-4 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-12 w-auto"
                    src={Logo}
                    alt="Your Company"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:white">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6  " aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {mobileNav.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <item.icon  className="h-6 w-6 flex-shrink-0 text-[#DE562C] " aria-hidden="true" />
                      <span className="ml-3 text-base font-medium ">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 pb-6 px-3">
             {isAuthenticated ? ( <div className='pb-3 ' >
             <h4 className='mb-3 font-[600] text-[1.3rem] text-center ' >Hii, {userProfile?.name}</h4>
             <div className="grid grid-cols-2 gap-y-6  ">
                {accountDetails.map((item) => (
                  <Link
                    key={item.name}
                  to={item.href}
                    className="text-base font-[500] py-2 px-3 hover:bg-[#3e3e3e42] mx-2 flex items-center justify-start text-gray-900 hover:text-gray-700"
                  >
                  
                 <item.icon  className="h-6 w-6 text-[#DE562C] flex-shrink-0 " aria-hidden="true" />
                      <span className="ml-3 text-base font-medium ">{item.name}</span>
                  </Link>
                ))}
              </div>
               <div className='  text-center mt-6 mb-2 text-white flex items-center justify-center ' >
                      <button onClick={onLogout} className='outline-none bg-[#DE562C] w-full  px-12 py-3 rounded-[10px] flex items-center justify-center ' ><ArrowLeftOnRectangleIcon className='w-[25px] mr-3' /> Sign Out</button>
                    </div>
               </div>) : (
                <div>
                <Link to='/login' >  <button  className='outline-none bg-[#DE562C] w-full text-white  px-12 py-3 rounded-[10px] flex items-center justify-center ' > Login<ArrowRightOnRectangleIcon className='w-[25px] ml-3' /></button></Link>
                </div>
               ) }
              
                
              
            </div>
          
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}