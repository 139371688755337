import React from 'react'
import { Input } from '@material-tailwind/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
const EditProfile = ({name,email,phone,setName,setEmail,setPhone,updateProfileInfo,emailError,emailErrorMessage,phoneError,phoneErrorMessage,emailChange,setEmailChange,phoneChange,setPhoneChange}) => {
  return (
    <div className='my-6 mx-8' >
        <div>
            <h4 className='text-center text-[#DE562C] font-[600] text-[1.5rem] ' >Edit your Profile</h4>
            <div className='xl:flex items-center justify-center flex-col ' >
            <div className='xl:w-[350px] w-full mt-4' >
                  <Input
                        className="    "
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                       
                        label='Name'
                        type="text"
                      />
                      </div>
                      <div className='xl:w-[350px] w-full mt-4'>
                       <Input
                       
                        value={email}
                         error={emailError ? true : false }
                        onChange={(e) => {
                          setEmailChange(true)
                          setEmail(e.target.value)}}
                       label='Email'
                        type="email"
                      /> 
                       {emailError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {emailErrorMessage && emailErrorMessage}</p>}
                      </div>
                      <div className='xl:w-[350px] w-full mt-4'>
                       <Input
                      error={phoneError ? true : false }
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value)
                          setEmailChange(true)
                        }}
                        label="Phone Number"
                        type="text"
                      />
                       {phoneError && <p className='text-red-400 text-[0.7rem] flex items-center justify-end ' ><InformationCircleIcon className='w-[15px] mr-2' /> {phoneErrorMessage && phoneErrorMessage}</p>}
                      </div>
                       <div className="" >

                     
                        <button
                       onClick={updateProfileInfo}
                        className="bg-[#DE562C] px-3 py-3 mt-10 rounded-[10px] xl:w-[350px] w-full  text-white font-[600] text-[12px] xl:text-[16px] "
                      >Edit Profile</button>
                      
                      </div>
            </div>
        </div>
    </div>
  )
}

export default EditProfile