import React from 'react'
import Secure from "../../asset/secure.png"
import Warrenty from "../../asset/warrenty.png"
import Support from "../../asset/support.png"
import Replacement from "../../asset/replacement.png"
import Lowprice from "../../asset/price.png"
import Delivery from "../../asset/delivery.png"
import Check from "../../asset/check.png"
import Genuine from "../../asset/genuine.png"
const Assurance = () => {
  return (
    <div style={{fontStyle:"DM Sans"}} className='xl:px-8 lg:px-6 px-4 xl:py-4' >
        <div>
            <h4 className='font-[700] text-[#121212] text-[1.4rem] text-left ' >Reviwo Assured</h4>
            <div className='flex items-center flex-wrap justify-between  xl:my-2' >
                <div className='bg-[#e4eff6]  border-[1px]  border-gray-200 xl:w-[250px] lg:w-[220px] w-[140px] my-2 lg:my-2 flex items-center justify-center  lg:px-4 lg:py-4 px-2 py-2 rounded-[10px] ' >
                
                  <img className='lg:w-[40px] w-[20px] lg:mr-3 mr-1' src={Genuine} alt="" />
                  <p className='lg:text-[1rem] text-[.5rem] text-[#151515] mt-0 font-[500] ' >100% Genuine Parts</p>
                </div>
                <div className='bg-[#e4eff6]  border-[1px] border-gray-200 xl:w-[250px] lg:w-[220px] w-[140px] my-2 lg:my-2 flex items-center justify-center  lg:px-4 lg:py-4 px-2 py-2 rounded-[10px] ' >
                
                  <img className='lg:w-[40px] w-[20px] lg:mr-3 mr-1' src={Check} alt="" />
                  <p className=' text-[#151515] lg:text-[1rem] text-[.5rem] mt-0 font-[500] ' >60+ Quality Checks </p>
                </div>
                  <div className='bg-[#e4eff6]  border-[1px] border-gray-200 xl:w-[250px] lg:w-[220px] w-[140px] my-2 lg:my-2 flex items-center justify-center  lg:px-4 lg:py-4 px-2 py-2 rounded-[10px] ' >
                
                  <img className='lg:w-[40px] w-[20px] lg:mr-3 mr-1' src={Delivery} alt="" />
                  <p className='lg:text-[1rem] text-[.5rem] text-[#151515]  font-[500] ' >Fast & Free Delivery </p>
                </div>
                  <div className='bg-[#e4eff6]  border-[1px] border-gray-200 xl:w-[250px] lg:w-[220px] w-[140px] my-2 lg:my-2 flex items-center justify-center  lg:px-4 lg:py-4 px-2 py-2 rounded-[10px] ' >
                
                  <img className='lg:w-[40px] w-[20px] lg:mr-3 mr-1' src={Lowprice} alt="" />
                  <p className='lg:text-[1rem] text-[.5rem] text-[#151515] mt-0 font-[500] ' >Low Price</p>
                </div>
                  <div className='bg-[#e4eff6]  border-[1px] border-gray-200 xl:w-[250px] lg:w-[220px] w-[140px] my-2 lg:my-2 flex items-center justify-center  lg:px-4 lg:py-4 px-2 py-2 rounded-[10px] ' >
                
                  <img className='lg:w-[40px] w-[20px] lg:mr-3 mr-1' src={Warrenty} alt="" />
                  <p className='lg:text-[1rem] text-[.5rem] text-[#151515]  font-[500] ' >1 Year Warrenty</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Assurance