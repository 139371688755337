import React from 'react'
import Iphone from "../../asset/iphone.png"
import Oneplus from "../../asset/oneplus8.png"
import Realme from "../../asset/realme8.png"
import ProductCard from './ProductCard'
import { Link } from 'react-router-dom'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'

const ProductRight = ({ products, page, showNext, showPrev }) => {

  return (
    <div className='lg:col-span-2  '>
      <div className='flex flex-col items-start justify-start  ' >
        {
          products && products.map((product) => (
            <Link to={`/product/${product.id}`} >
              <ProductCard product={product} />
            </Link>
          ))
        }
      </div>
      <div className='flex items-center justify-center' >


      </div>
    </div>
  )
}

export default ProductRight