import React, { useState,useEffect} from 'react'
import Navbar from '../components/Layout/Navbar'
import ProfileNav from '../components/profile/ProfileNav'
import { useSelector,useDispatch } from 'react-redux'
import ProfileDetails from '../components/profile/ProfileDetails'
import Footer from '../components/Layout/Footer'
import SavedAddress from '../components/profile/SavedAddress'
import { loadUser, updateProfile } from '../actions/userActions'
import {auth,db,googleProvider} from "../firebase.config"
import { updateEmail } from 'firebase/auth'
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import EditProfile from '../components/profile/EditProfile'
import ChatbotButton from '../components/Layout/ChatbotButton'
import { ToastContainer, toast } from 'react-toastify';
import { database } from "../firebase.config";
import { ref, set, push, onValue,remove } from "firebase/database";
const Profile = () => {
    const { error, loading, isAuthenticated,user,userProfile } = useSelector(
    (state) => state.users
  );
    const dispatch = useDispatch();

  const [name, setName] = useState(userProfile?.name ? userProfile.name : "")
  const [email, setEmail] = useState(userProfile?.email ? userProfile?.email : "")
  const [emailChange, setEmailChange] = useState(false)

  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState("")
  const [phone, setPhone] = useState(userProfile?.phone ? userProfile.phone : "")
  const [phoneError, setPhoneError] = useState(false)
  const [phoneChange, setPhoneChange] = useState(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
 const [addresses, setAddresses] = useState(userProfile?.addresses ? userProfile?.addresses : [])     
  // const [addAddress, setAddAddress] = useState(false);
  const [briefAddress, setBriefAddress] = useState("");
  // const [pinCodeError, setPinCodeError] = useState(false)
  const [pinCode, setPinCode] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("")
  const [addressType, setAddressType] = useState("")
  const [isDefault, setIsDefault] = useState("")
  const [addressPersonName, setAddressPersonName] = useState("")
  let [isOpen, setIsOpen] = useState(false);
  const [addressAdded, setAddressAdded] = useState(false)
  const [addressRemoved, setAddressRemoved] = useState(false)
  const [role, setRole] = useState(userProfile?.role ? userProfile.role : '')
  const [orderList, setOrderList] = useState([])
  const updateProfileInfo = async  () => {
    const userRef = collection(db, "user");
    const emailQuery = query(userRef, where("email", "==", email));
    const getEmailInfo = await getDocs(emailQuery);
    const userMobileRef = collection(db, "user");
    const mobileQuery = query(userRef, where("phone", "==", phone));
    const getMobileInfo = await getDocs(mobileQuery);
     var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      var phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    if(email!=="" && emailChange && getEmailInfo.docs.length!==0 ){
      alert("Email Already Present")
      setEmailError(true)
      setEmailErrorMessage("Email Already Present")
    }
    if(email=== ""  || emailRegex.test(email)===false){
       alert("Enter Valid Email")
      setEmailError(true)
      setEmailErrorMessage("Enter a Valid Email")
    }
    if(phone!=="" && phoneChange && getMobileInfo.docs.length!==0 ){
       alert("Phone Number Already Present")
      setPhoneError(true)
      setPhoneErrorMessage("Phone Number is Already Present")
    }
      if(phone!==""  && phoneRegex.test(phone)!==true){
      alert("Enter Valid Phone Number")
      setPhoneError(true)
      setPhoneErrorMessage("Enter a Valid Phone Number")
    }
    else{
       updateEmail(auth.currentUser, email).then(() => {
        dispatch(updateProfile(user,name,email,phone,role))
         toast("Profile Saved Successfully")
    dispatch(loadUser(user))
    }).catch((error) => {
      alert(error.message, "Please Login Again To Change the Information")
     setEmail(userProfile?.email ? userProfile?.email : "")
    });
    
    }
   
   
  }
  console.log(briefAddress)
  const updateAddressInfo = async ()=>{
   
      if(userProfile){
        const productListRef = ref(database, "addresses/" + user);
    const newProductRef = push(productListRef);
    set(newProductRef, {
     userId:user,
     briefAddress:briefAddress,
     addressPersonName:addressPersonName,
     alternateNumber:alternateNumber,
     city:city,
     state:state,
     pincode:pinCode,
     default:isDefault,
     addressType:addressType
    }).then(()=>{
   
       setBriefAddress("")
    setAlternateNumber("")
    setAddressPersonName("")
    setCity("")
    setState("")
    setPinCode("")
    console.log(addresses)
    
     setAddressAdded(false)
    console.log(addresses)
    setIsOpen(false)
    })
      }
    }
     

  
    const fetchOrders = async () =>{
     const q = query(collection(db, "orders"), where("userId", "==", user));
       console.log("newOrder")
 
   
  const querySnapshot = await getDocs(q);
   querySnapshot.forEach((doc) => {
     setOrderList((prev)=>[...prev,{
      id:doc.id,
      ...doc.data()
     }])
  
    });
    }

    const fetchAddresses = () =>{
      const reviewCountRef = ref(database, 'addresses/' + user);

    onValue(reviewCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newProducts = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));

      setAddresses(newProducts)
      }
   


    },);
    }
    const removeAddresss = (id) =>{
    remove(ref(database,"addresses/"+user+"/"+id)).
    then(()=>{
      toast("Address Removed Succesfully")
      setAddressRemoved(true)
    }).catch((err)=>{
      console.log(err.message)
    })
    }

  useEffect(() => {
     dispatch(loadUser(user))
     setAddressAdded(false)
     fetchOrders()
     fetchAddresses()
  }, [user,addressRemoved])
  console.log(orderList)

  const [active, setActive] = useState("profile")
  return (
    <div style={{ fontFamily: "DM Sans" }} className='bg-[#e9f3f9] ' >
        <Navbar />
        <ChatbotButton />
        <ToastContainer />
        <div className='xl:px-36 px-3 xl:py-12 mt-3  ' >
            <div className='bg-white px-2 xl:px-12 py-8 rounded-[20px]' >
                <ProfileNav active={active} setActive={setActive} />
                    {
                        active==="profile" && <ProfileDetails userProfile={userProfile} />
                    }
                     {
                        active==="address" && <SavedAddress removeAddresss={removeAddresss} addressType={addressType} setAddressType={setAddressType} setIsDefault={setIsDefault} isDefault={isDefault} addressAdded={addressAdded} setAddressAdded={setAddressAdded} addressPersonName={addressPersonName} setAddressPersonName={setAddressPersonName} state={state} setState={setState} briefAddress={briefAddress} setBriefAddress={setBriefAddress} city={city} setCity={setCity} pinCode={pinCode} setPinCode={setPinCode} alternateNumber={alternateNumber} setAddresses={setAddresses} setAlternateNumber={setAlternateNumber} updateAddressInfo={updateAddressInfo} updateProfileInfo={updateProfileInfo} isOpen={isOpen} setIsOpen={setIsOpen}  name={name} setName={setName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} addresses={addresses}  />
                    }
                    {
                        active==="edit" && <EditProfile phoneChange={phoneChange} setPhoneChange={setPhoneChange} emailChange={emailChange} setEmailChange={setEmailChange} phoneError={phoneError} phoneErrorMessage={phoneErrorMessage}  addressAdded={addressAdded} emailError={emailError} emailErrorMessage={emailErrorMessage} setAddressAdded={setAddressAdded} addressPersonName={addressPersonName} setAddressPersonName={setAddressPersonName} state={state} setState={setState} briefAddress={briefAddress} setBriefAddress={setBriefAddress} city={city} setCity={setCity} pinCode={pinCode} setPinCode={setPinCode} alternateNumber={alternateNumber} setAddresses={setAddresses} setAlternateNumber={setAlternateNumber} updateAddressInfo={updateAddressInfo} updateProfileInfo={updateProfileInfo} isOpen={isOpen} setIsOpen={setIsOpen}  name={name} setName={setName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} addresses={addresses}  />
                    }
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Profile