import React from 'react'
import Cover1 from "../../asset/cover1.png"
import Cover2 from "../../asset/cover2.png"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Lazy
} from "swiper";

import Banner1 from "../../asset/banner1.png"
import Banner2 from "../../asset/banner2.png"
import Banner3 from "../../asset/banner3.png"
// Import Swiper styles
import "swiper/css";
import 'swiper/css/pagination';
import HomeBanner from './HomeBanner';
const HomeCarousel = ({homeBanner}) => {
    const data = [
       Banner1, Banner2, Banner3
    ]
    console.log(homeBanner)
  return (
    <div>
      { homeBanner.length !== 0 ?  <Swiper   spaceBetween={0}
          className=""
          draggable={true}
       lazy={false}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction:false,
          }}
          pagination={{
          clickable: true,
        }}
          loop={true}
          style={{
            '--swiper-pagination-progressbar-bg-color': '#DE562C',
            '--swiper-pagination-color': '#fff'
          }}
          modules={[Autoplay,Pagination,]}
         >
            {homeBanner.map((banner,key)=>(
                <SwiperSlide>
                    <HomeBanner banner={banner} />
                </SwiperSlide>
            ))}
        </Swiper> : (
            <div className='h-[450px] bg-gray-200' >

          </div>
        )
        }
    </div>
  )
}

export default HomeCarousel