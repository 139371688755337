import React, { useState } from 'react'
import Navbar from '../components/Layout/Navbar'
import ContactBanner from '../components/Contact/ContactBanner'
import NeedHelp from '../components/Contact/NeedHelp'
import ReachUs from '../components/Contact/ReachUs'
import ContactForm from '../components/Contact/ContactForm'
import Footer from '../components/Layout/Footer'
import { useSelector } from 'react-redux'
import { auth, db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import ChatbotButton from '../components/Layout/ChatbotButton'
const Contact = () => {
  const { error, loading, isAuthenticated, user, userProfile } = useSelector(
    (state) => state.users
  );
  const [formData, setFormData] = useState({
    name: userProfile?.name ? userProfile.name : "",
    email:userProfile?.email ? userProfile.email : "",
    phone:userProfile?.phone ? userProfile.phone : "",
    subject:"",
    message:""
  })

  const handleSubmit = async (e) =>{
   e.preventDefault()
    console.log("clieked")
      await addDoc(collection(db, "contactForm"), formData).then((res) => {
      console.log("done");
       setFormData({
        name: userProfile ? userProfile?.name : "",
        email: userProfile ? userProfile?.email : "",
        phone: userProfile ? Number(userProfile?.phone) : "",
        subject: "",
        message: "",
      });
    });
  }

  return (
    <div className='bg-[#e9f3f9]'>
        <Navbar />
        <ChatbotButton />
        <ContactBanner />
       
        <ReachUs />
        <ContactForm handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} />
        <Footer />
    </div>
  )
}

export default Contact