import React from 'react'
import CartCard from './CartCard'
import { ShoppingBagIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
const CartList = ({cartItems,totalItemPrice,setTotalItemPrice,setTotalRealItemPrice}) => {
  return (
    <div className='col-span-3 bg-white  xl:px-6 xl:py-8 rounded-[20px] ' >
                    {
                        cartItems.length !==0 ? (
                            cartItems.map((item)=>(
                                <div>
                              <CartCard setTotalRealItemPrice={setTotalRealItemPrice} setTotalItemPrice={setTotalItemPrice} totalItemPrice={totalItemPrice} product={item} />
                            
                </div>
                            ))
                        ):
                        (
                          <div className='flex items-center justify-center h-full flex-col ' >
                            <h3 className='text-[1.2rem]' >No Items in the Cart</h3>
                            <Link to="/shopAll" >
                            <button className='px-6 mt-3 rounded-[10px] py-3 bg-[#DE562C] text-white' >Shop Now</button>
                            </Link>
                            </div>
                        )
                    }
                    
                </div>
  )
}

export default CartList