import React from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { removeItemsFromCart } from '../../actions/cartActions'
import { useSelector,useDispatch } from 'react-redux'
const CartCard = ({product,totalItemPrice,setTotalItemPrice,setTotalRealItemPrice}) => {
     const dispatch = useDispatch();
    const removeItemFromCart = () =>{
        dispatch(removeItemsFromCart(product.product))
        setTotalItemPrice((prev)=>(prev-Number(product.price)))
        setTotalRealItemPrice((prev=>(prev-Number(product.realPrice))))
     }
  return (
     <div className='bg-white rounded-[30px]  w-[full]  px-3 xl:px-6 mb-8 border-b-[1px] border-gray-200 py-8 ' >
        <div className='grid grid-cols-4 items-center justify-center grid-flow-col'  >
            <div className='xl:col-span-1 col-span-2 mr-6' >
                <img className='rounded-[15px]' src={product.coverImage} alt="" />
            </div>
            <div className='xl:col-span-3 col-span-2 ' >
            <div className='flex items-start  xl:items-center justify-start flex-col xl:flex-row' >
                <h3 className='font-[600] text-[0.8rem] xl:w-[450px]  xl:text-[1.5rem] text-[#121212] ' >{product.productName}  </h3>
                <div className='mx-4 px-4 hidden xl:block text-[0.6rem] py-2 bg-green-500 text-white rounded-[15px] ' >
                    <p>Assured</p>
                </div>
                </div>
                <div className='flex items-center justify-start my-1' >
                    <p className='font-[600] text-[0.7rem] xl:text-[1.5rem] mr-2  text-[#1287E3]' >Rs. {product.price}</p>
                    <p className='text-gray-400 text-[0.9rem] line-through xl:mr-2 mr-1 ' >Rs. {product.realPrice}</p>
                    <p className='text-green-500 text-[0.9rem] font-[500]' >Save Rs.{product.realPrice-product.price}</p>
                </div>
                <div>
                    <p className='text-gray-400 text-[0.9rem] ' >Free Delivery . Cod Avaliable</p>
                    <button  onClick={removeItemFromCart}  className='bg-red-500 text-white flex rounded-[10px] mt-4 items-center text-[0.6rem] xl:text-[1rem] justify-center w-full xl:w-[150px] py-2 xl:py-3 px-2' >
                        <TrashIcon className='text-white w-[15px] xl:w-[25px] mr-3 ' /> Remove
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CartCard