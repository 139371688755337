import React from 'react'
import About from "../../asset/aboutus.png";
const Aboutus = () => {
  return (
     <div className="lg:py-4 py-4 xl:px-6" style={{ fontFamily: "DM Sans" }}>
      <div className="flex flex-col-reverse xl:flex-row items-center justify-around lg:px-8 px-3 lg:py-12">
      <div>
          <img
            className="lg:w-[400px] my-2 w-full rounded-[10px] "
            src={About}
            alt=""
          />
        </div>
        <div className="xl:w-[750px] mt-0 xl:mb-0 text-[1.2rem] ">
          <h4 className="text-left text-[2rem] lg:text-[3rem] font-[600] text-[#121212] ">
            About Us
          </h4>
          <p className="xl:text-[1rem] text-gray-600 text-[0.6rem] ">
          Welcome to Reviwo, your destination for exquisite refurbished electronics! 
Our mission is simple, to provide you with unparalleled devices that not only save you a fortune but also make a resounding impact in safeguarding our precious environment.
At Reviwo, sustainability lies at the core of everything we do. <br/> Each refurbished device embarks on a new chapter, extending its life cycle and significantly curbing electronic waste and carbon footprint. Our unwavering commitment to a zero electronic waste policy ensures that your purchase resonates with our eco-conscious values, making you an Eco Hero!
Quality reigns supreme in our realm. Our adept technicians subject every device to rigorous testing and scrutiny, guaranteeing that it meets and exceeds industry standards. 
Rest assured, our products exude unmatched reliability and efficiency.
Join us in forging a greener future while relishing cutting-edge technology. We take pride in offering nothing short of impeccable, cost-effective and sustainable electronics. Welcome to the extraordinary world of refurbished excellence with Reviwo.

           
          </p>
          <div></div>
        </div>
        
      </div>
    </div>
  )
}

export default Aboutus