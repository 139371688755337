import React from 'react'
import Mark from "../../asset/mark.png"
const AboutBanner = () => {
  return (
    <div style={{ fontFamily: "DM Sans" }} className='rounded-[30px] lg:mx-4 my-2 mx-2 py-8 lg:my-4 bg-gradient-to-r from-[#2E3191] to-[#1287E3] ' >
        <div className='xl:px-16 xl:py-12 flex px-4 flex-col xl:flex-row items-center justify-center xl:justify-around' >
            <div className='xl:w-[500px] xl:mx-12' >
                <h1 className='font-[600] text-white xl:leading-[4rem] text-[1.4rem]  xl:text-[3rem] ' >Reviving Technology For Tomorrow</h1>
                <p className='text-[#e1e1e1] lg:text-[1rem] text-[.6rem] xl:mt-6' >Our mission is simple, to provide you with unparalleled devices that not only save you a fortune but also make a resounding impact in safeguarding our precious environment.</p>
            </div>
            <div >
                <img className='xl:w-[250px] lg:mt-6 xl:mt-0 w-[150px] lg:mx-12  ' src={Mark} alt="" />
            </div>
        </div>
    </div>
  )
}

export default AboutBanner