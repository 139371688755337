import React from 'react'

const ProductCard = ({ product }) => {
    return (
        <div className='bg-white rounded-[15px] relative lg:rounded-[20px]  w-[full] mx-2  lg:mx-8   xl:mx-16  my-2 lg:mb-4 border-[1px] border-gray-200 ' >
        <div className='absolute top-1 left-1 rounded-[10px] lg:px-2 px-1 py-1 lg:py-2 bg-red-400 ' >
            <p className='text-white text-[.3rem] lg:text-[0.6rem] ' >Save Rs.{product.realPrice - product.price}</p>
        </div>
            <div className='grid grid-cols-4 lg:py-6 px-3 py-4 lg:px-6  items-center justify-center grid-flow-col'  >
                <div className='col-span-1 lg:col-span-1 mr-3 lg:mr-6' >
                    <img className='rounded-[10px] lg:rounded-[15px] lg:w-full w-[100px] ' src={product.coverImage} alt="" />
                </div>
                <div className='lg:col-span-3 col-span-3  ' >
                    <div className='flex items-start  lg:items-center flex-col lg:flex-row justify-start' >
                        <h3 className='font-[600] lg:w-[450px] text-[0.8rem] lg:text-[1.1rem] xl:text-[1.5rem] text-[#121212] ' >{product.productName} <span className='xl:text-[0.9rem] text-[0.6rem] text-gray-400 ' >({product.color})</span>  </h3>
                        <div className='mx-4 hidden lg:block px-4 text-[0.6rem] py-2 bg-green-500 text-white rounded-[15px] ' >
                            <p>Assured</p>
                        </div>
                    </div>
                    <div className='flex items-center justify-start my-0' >
                        <p className='font-[600] text-[0.7rem] lg:text-[1rem] xl:text-[1.3rem] mr-2  text-[#1287E3]' >Rs. {product.showPrice && product.showPrice}</p>
                        <p className='text-gray-400 text-[0.4rem] lg:text-[.6rem] xl:text-[.8rem] line-through mr-2 ' >Rs. {product.realPrice}</p>
                        <p className='text-green-500 text-[0.4rem] lg:text-[.6rem] xl:text-[.8rem] font-[500]' >Save Rs.{product.realPrice - product.price}</p>
                    </div>
                    <div>
                        <p className='text-gray-600 text-[0.5rem] lg:text-[.8rem] xl:text-[1rem] ' >{product?.ram && product.ram} {product?.ram && ","} {product?.storage}</p>
                         <p className='text-gray-500 text-[0.5rem] lg:text-[.8rem] xl:text-[1rem] ' >Free Delivery - C.O.D Available</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard