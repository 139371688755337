import React from 'react'
import Navbar from '../components/Layout/Navbar'
import AllCategory from '../components/Brand/AllCategory'
import Footer from '../components/Layout/Footer'
import ChatbotButton from '../components/Layout/ChatbotButton'

const ShopByCategory = () => {
  return (
    <div style={{ fontFamily: "DM Sans" }} className='min-h-[100vh] bg-[#ffffff]' >
        <Navbar />
        <ChatbotButton />
        <AllCategory />
        <Footer />
    </div>
  )
}

export default ShopByCategory