import { Input, Radio } from '@material-tailwind/react'
import React from 'react'
import { HomeIcon, BuildingOfficeIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
const CheckoutLeft = ({orderData,setOrderData,userProfile,handlePayment,addresses}) => {
  const navigate = useNavigate()
  return (
    <div className='col-span-3 lg:my-16 my-6 mx-4 lg:mx-16 ' >
        <div>
            <h4 className='text-[1.5rem] text-center lg:text-left font-[600]' >Shipping Address</h4>
            <div className='mt-3' >
                <div className='flex items-start flex-col lg:flex-row justify-start lg:gap-4' >
                  <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.name}
                        onChange={(e)=>{setOrderData({
        ...orderData,
        name: e.target.value
      })}}
                        placeholder="Enter your Name "
                        type="text"
                      />
                          <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.email}
                        onChange={(e)=>{setOrderData({
        ...orderData,
        email: e.target.value
      })}}
                        placeholder="Enter your Email "
                        type="text"
                      />
                </div>
                <div>
                      <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.addressLineOne}
                        onChange={(e)=>{setOrderData({
        ...orderData,
        addressLineOne: e.target.value
      })}}
                        placeholder="Enter your Flat No., Building, Locality, Area "
                        type="text"
                      />
                </div>
                 <div className='flex items-start flex-col lg:flex-row justify-start lg:gap-4' >
                      <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.city}
                        onChange={(e)=>{setOrderData({
        ...orderData,
        city: e.target.value
      })}}
                        placeholder="City "
                        type="text"
                      />
                        <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.state}
                        onChange={(e)=>{setOrderData({
        ...orderData,
        state: e.target.value
      })}}
                        placeholder="State or Union Territory "
                        type="text"
                      />
                </div>
                 <div className='flex items-start flex-col lg:flex-row justify-start lg:gap-4' >
                      <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.pinCode}
                        onChange={(e)=>{setOrderData({
        ...orderData,
        pinCode: e.target.value
      })}}
                        placeholder="Pin Code "
                        type="text"
                      />
                       <input
                        className="py-3 mt-2 lg:mt-6 px-3 w-[100%] border-[1px] border-gray-200 outline-none rounded-[10px] "
                        value={orderData.alternateNumber}
                       
                        onChange={(e)=>{setOrderData({
        ...orderData,
        alternateNumber: e.target.value
      })}}
                        placeholder="Alternate Phone Number "
                        type="number"
                      />
                </div>
                <div className='flex items-center justify-center lg:justify-between' >
                 <button onClick={()=>navigate(-1)} className='lg:w-[200px] w-[150px] border-[1px] mr-3 lg:mr-0 border-blue-400 mt-6 text-[.7rem] lg:text-[1rem] text-blue-400 py-3 rounded-lg font-[600]'>
                    Back to Cart
                 </button>
                     <button onClick={handlePayment} className='lg:w-[200px] w-[150px] bg-blue-400 text-[.7rem] lg:text-[1rem] mt-6 text-white py-3 rounded-lg font-[600]' >
                Proceed to Payment
            </button>
                   
                </div>
                <div className='my-6'>
                  <h4 className='lg:text-[1.5rem] text-[1rem] font-[600]'>Choose a Payment Method</h4>
                  <div className='my-2 py-3 px-4 w-full bg-gray-200 rounded-lg ' >
                    <Radio defaultChecked label="Cash on Delivery" onChange={(e) => setOrderData({
                        ...orderData,
                        paymentMethod:'cashOnDelivery',
                    }) } className=''  />
                  </div>
                   
                </div>
              { addresses &&  <div className='my-6'>
                    <h4 className='lg:text-[1.5rem] text-[1rem] font-[600]' >Choose from the Addresses:</h4>
                    <div className='flex items-center lg:justify-center justify-start gap-3 flex-wrap w-full lg:w-[250px] ' >
                    {
                      addresses &&  addresses.map((address)=>(
                        <div  onClick={()=>{
                            setOrderData({
                                ...orderData,
                                name:address.addressPersonName,
                                alternateNumber:Number(address.alternateNumber),
                                addressLineOne:address.briefAddress,
                                city:address.city,
                                state:address.state,
                                pinCode:address.pinCode
                            })
                        }} className='lg:w-full w-[170px] py-4 mt-3 cursor-pointer px-6 rounded-xl bg-gray-200' >
                         {
            address.addressType === "home" &&   <HomeIcon className="w-[25px] text-[#DE562C]" />
           }
            {
            address.addressType === "office" &&   <BuildingOfficeIcon className="w-[25px] text-[#DE562C]" />
           }
           {
            address.addressType === "other" &&    <BuildingLibraryIcon className="w-[25px] text-[#DE562C]" />
           }
           {address.default && <h4 className='font-[500] ' >Default Address</h4>  }
                            <p className='lg:text-[1.0rem] text-[.6rem] font-[400] ' >{address.addressPersonName}</p>
                            <p className='lg:text-[1.0rem] text-[.6rem] font-[400] '>{address.alternateNumber}</p>
                            <p className='lg:text-[1.0rem] text-[.6rem] font-[400] '>{address.briefAddress}</p>
                            <p className='lg:text-[1.0rem] text-[.6rem] font-[400] '>{address.city}, {address.state}, {address.pinCode}</p>
                        </div>
                      ))
                    }
                    </div>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default CheckoutLeft