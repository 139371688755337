import React from 'react'
import Check from "../../asset/check.png"
import Delivery from "../../asset/delivery.png"
import Safe from "../../asset/safe.png"
import Exchange from "../../asset/exchange.png"
import useWindowDimensions from "../Layout/useWindowDimension"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
const Assurance = () => {
     const {width,height} = useWindowDimensions()
  return (
    <div style={{fontFamily:"DM Sans"}} className='mt-8 mb-12  ' >
       
      { width >500 &&  <div className='flex mt-12 xl:mx-4 lg:mx-4 items-start justify-center flex-wrap lg:gap-4 xl:gap-6' > 
            <div className='xl:w-[250px]  lg:w-[230px] border-[1px] border-gray-300 bg-white px-6 py-4 rounded-[10px] w-[140px] xl:mx-6  flex items-center justify-center   ' >
                <img className='xl:w-[45px] w-[40px]  mb-3 mr-4' src={Check} alt="" />
                <p className='text-center' >Extensive 60 Points Check</p>
            </div>
             <div className='xl:w-[250px] lg:w-[230px] bg-white px-6 py-4 rounded-[10px] w-[140px] xl:mx-6  flex items-center justify-center   ' >
                <img className='xl:w-[45px] w-[40px] mb-3 mr-4' src={Delivery} alt="" />
                <p className='text-center' >Free Home Delivery</p>
            </div>
              <div className='xl:w-[250px] lg:w-[230px] bg-white px-6 py-4 rounded-[10px] w-[140px] xl:mx-6  flex items-center justify-center   ' >
                <img className='xl:w-[45px] w-[40px] mb-3 mr-4' src={Safe} alt="" />
                <p className='text-center' >1 Year Warranty</p>
            </div>
              <div className='xl:w-[250px] lg:w-[230px] bg-white px-6 py-4 rounded-[10px] w-[140px] xl:mx-6  flex items-center justify-center   ' >
                <img className='xl:w-[45px] w-[40px] mb-3 mr-4' src={Exchange} alt="" />
                <p className='text-center  ' >7 Days Exchange Policy</p>
            </div>
        </div>}
        <div className='my-6 mx-6' >
    {width <= 500 && <Swiper   spaceBetween={6}
          className=""
          draggable={true}
          breakpoints={{
        300: {
          width: 300,
          slidesPerView: 1.5,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
          1200:{
          width:1440,
          slidesPerView:3.3
        },
       
         1440:{
          width:1440,
          slidesPerView:3.5
        }
      }}
      loop={true}
          autoplay={{
            delay: 800,
            disableOnInteraction:false,
          }}
       
          modules={[Autoplay]}
        >
           <SwiperSlide>
             <div className='xl:w-[200px] w-[180px] xl:mx-12 mr-4  flex items-center justify-center  ' >
                <img className='xl:w-[45px] w-[40px]  mb-3' src={Check} alt="" />
                <p className='text-center' >Extensive 60 Points Check</p>
            </div>
           </SwiperSlide>
                <SwiperSlide>
                 <div className='xl:w-[200px] w-[180px] xl:mx-12 mr-4  flex items-center justify-center  ' >
                <img className='xl:w-[45px] w-[40px] mb-3' src={Delivery} alt="" />
                <p className='text-center' >Free Home Delivery</p>
            </div>
           </SwiperSlide>
             <SwiperSlide>
                <div className='xl:w-[200px] w-[180px] xl:mx-12 mr-4  flex items-center justify-center  ' >
                <img className='xl:w-[45px] w-[40px] mb-3' src={Safe} alt="" />
                <p className='text-center' >1 Year Warrenty</p>
            </div>
           </SwiperSlide>
               <SwiperSlide>
                <div className='xl:w-[200px] w-[180px] xl:mx-12 mr-4  flex items-center justify-center  ' >
                <img className='xl:w-[45px] w-[40px] mb-3' src={Exchange} alt="" />
                <p className='text-center  ' >7 Days Exchange Policy</p>
            </div>
           </SwiperSlide>
             
             
          </Swiper>}
          </div>
    </div>
  )
}

export default Assurance