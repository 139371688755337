import React from "react";
import About from "../../asset/about.png";
const AboutContent = () => {
  return (
    <div className="lg:py-4 py-4 xl:px-6" style={{ fontFamily: "DM Sans" }}>
      <div className="flex flex-col-reverse xl:flex-row items-center justify-around lg:px-8 px-3 lg:py-12">
        <div className="xl:w-[750px] mt-0 xl:mb-0 text-[1.2rem] ">
          <h4 className="text-left text-[2rem] lg:text-[3rem] font-[600] text-[#121212] ">
            Why Reviwo
          </h4>
          <p className="xl:text-[1rem] text-gray-600 text-[0.6rem] ">
            At Reviwo, we believe in sustainability and cost-effectiveness. With
            every refurbished phone we extend the life of the device and reduce
            the use of resources to produce the new ones. Reviwo Devices under
            thorough testing and quality checks making the refurbished device as
            good as a new one.
            <br /> Our zero carbon footprint policy makes Refurbished Devices
            more durable for the environment, ensuring no electronic waste is
            created. Count on us for a first-rate experience, from quality
            assurance to customer support. We bring to you a wide range of
            devices tailored to your preferences and budget. Our qualified
            technicians guarantee thorough quality checks, delivering devices as
            good as new. We carefully evaluate our mobiles through 60 parameters
            to provide top-notch refurbished products. Our commitment lies in
            revitalizing global resources and handpicking the finest candidates
            for our rejuvenation initiative.
            <span className="font-[600] text-[0.9rem] lg:text-[1.3rem] ">
              {" "}
              Experience Reviwo : Where 'Refurb' meets 'Fab' in your pocket
            </span>
          </p>
          <div></div>
        </div>
        <div>
          <img
            className="lg:w-[400px] w-full rounded-[10px] "
            src={About}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
