import React from 'react'
import Screen from "../../asset/offer/screen.png"
import Battery from "../../asset/offer/battery.png"
import Chipset from "../../asset/offer/chipset.png"
import Storage from "../../asset/offer/storage.png"
const MoreInformation = ({product}) => {
  return (
    <div className='lg:my-8 my-8 col-span-1 xl:mx-8 mx-4' >
            <h4 className='text-[1.4rem] font-[600]' >Top Specs</h4>
            <div className='flex mt-4 items-center flex-wrap  justify-between' >
                <div className='bg-gradient-to-r from-[#E36529] to-[#db1818] flex items-center justify-start text-white xl:w-[310px] w-[140px] lg:w-[240px] rounded-[10px] xl:px-6 my-2 lg:mb-6 xl:py-4 lg:px-2 lg-py-2 px-4 py-2' >
                 <div>
                  <img className='lg:w-[50px] w-[25px] lg:mr-6 mr-2' src={Screen} alt="" />
                </div>
                <div>
                <h4 className='lg:text-[1.3rem] text-[.7rem] font-[600] ' >Screen Size</h4>
                <p className='lg:text-[.8rem] text-[.4rem] text-gray-100 ' >{product?.brandSpecifications && product?.brandSpecifications[1]?.details[1]?.value}</p>
                </div>
               
                </div>
                <div className='bg-gradient-to-r from-[#E36529] to-[#db1818] flex items-center justify-start text-white xl:w-[310px] w-[140px] lg:w-[240px] rounded-[10px] xl:px-6 my-2 lg:mb-6 xl:py-4 lg:px-2 lg-py-2 px-4 py-2' >
                <div>
                  <img className='lg:w-[50px] w-[25px] lg:mr-6 mr-2' src={Battery} alt="" />
                </div>
                <div>
                <h4 className='lg:text-[1.3rem] text-[.7rem] font-[600] ' >Battery</h4>
                <p className='lg:text-[.8rem] text-[.4rem] text-gray-100 ' >{product?.brandSpecifications && product?.brandSpecifications[1]?.details[6]?.value}, {product.brandSpecifications && product?.brandSpecifications[1]?.details[7]?.value && product?.brandSpecifications[1]?.details[7]?.value}</p>
                </div>
                </div>
                
                   <div className='bg-gradient-to-r from-[#E36529] to-[#db1818] flex items-center justify-start text-white xl:w-[310px] w-[140px] lg:w-[240px] rounded-[10px] xl:px-6 my-2 lg:mb-6 xl:py-4 lg:px-2 lg-py-2 px-4 py-2' >
                     <div>
                  <img className='lg:w-[50px] w-[25px] lg:mr-6 mr-2' src={Chipset} alt="" />
                </div>
                <div>
                <h4 className='lg:text-[1.3rem] text-[.7rem] font-[600] ' >Chipset</h4>
                <p className='lg:text-[.8rem] text-[.4rem] text-gray-100 ' >{product?.brandSpecifications && product?.brandSpecifications[1]?.details[5]?.value}</p>
                </div>
                </div>
                 
                
                 <div className='bg-gradient-to-r from-[#E36529] to-[#db1818] flex items-center justify-start text-white xl:w-[310px] w-[140px] lg:w-[240px] rounded-[10px] xl:px-6 my-2 lg:mb-6 xl:py-4 lg:px-2 lg-py-2 px-4 py-2' >
                 <div>
                  <img className='lg:w-[50px] w-[25px] lg:mr-6 mr-2' src={Storage} alt="" />
                </div>
                <div>
                <h4 className='lg:text-[1.3rem] text-[.7rem] font-[600] ' >Storage</h4>
                <p className='lg:text-[.8rem] text-[.4rem] text-gray-100 ' >{product?.ram} / {product?.storage}</p>
                </div>
                </div>
                
            </div>
    </div>
  )
}

export default MoreInformation