import React from 'react'
import Navbar from '../components/Layout/Navbar'
import AboutBanner from '../components/About/AboutBanner'
import AboutContent from '../components/About/AboutContent'
import Team from '../components/About/Team'
import Footer from '../components/Layout/Footer'
import WhatWillOffer from '../components/About/WhatWillOffer'
import Aboutus from '../components/About/Aboutus'
import ChatbotButton from '../components/Layout/ChatbotButton'

const About = () => {
  return (
    <div className='bg-[#ffffff]' >
        <Navbar />
        <ChatbotButton />
        <AboutBanner />
        <AboutContent />
       <WhatWillOffer />
       <Aboutus />
        <Footer />
    </div>
  )
}

export default About