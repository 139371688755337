import React from 'react'
import Apple from "../../asset/brandlogo/apple.png"
import Acer from "../../asset/brandlogo/acer.png"
import Samsung from "../../asset/brandlogo/samsung.png"
import Realme from "../../asset/brandlogo/realme.png"
import Oppo from "../../asset/brandlogo/oppo.png"
import Vivo from "../../asset/brandlogo/vivo.png"

import Oneplus from "../../asset/brandlogo/oneplus.png"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Grid
} from "swiper";
// Import Swiper styles
import "swiper/css";
import { brandData } from '../../data/BrandData'
import { Link } from 'react-router-dom'
const BrandList = ({brandList}) => {
  return (
    <div style={{fontFamily:"DM Sans"}} className='lg:py-2 py-3 px-3 lg:px-0' >
        <div className='lg:px-12   ' >
              <h4 className='font-[600] text-[1rem] lg:text-[1.5rem] '>Shop By Brand</h4>
          
            <div className='pb-2 pt-3 lg:pt-4 ' >
                <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          breakpoints={{
        300: {
          width: 300,
          slidesPerView: 3,
        },
        780: {
          width: 780,
          slidesPerView: 3.3,
        },
        1000:{
          width:1000,
          slidesPerView:6
        },
       
         1440:{
          width:1440,
          slidesPerView:9
        }
      }}
      loop={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction:false,
          }}
          navigation={true}
                     style={{
         '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
          '--swiper-pagination-color': '#DE562C',
        }}
       
          modules={[Autoplay,Navigation]}
        >
        {
         brandData && brandData?.slice(0,11).map((brand)=>(
           <SwiperSlide>
           <Link to={`/shop/brand/${brand.name}`} >
           <div className=' ' >
               
                    <img className='lg:rounded-[10px] rounded-[5px] lg:w-[110px] w-[70px] ' src={brand.image} />
                  
             
                
                </div>
                </Link>
             </SwiperSlide>
         ))
        }     
          </Swiper>
          
            </div>
              <div className='pb-2 pt-3 lg:pt-4 ' >
                <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          breakpoints={{
          300: {
          width: 300,
          slidesPerView: 3,
        },
        780: {
          width: 780,
          slidesPerView: 3.3,
        },
        1000:{
          width:1000,
          slidesPerView:6
        },
       
         1440:{
          width:1440,
          slidesPerView:9
        }
      }}
      loop={false}
          autoplay={{
            delay: 2500,
            disableOnInteraction:false,
          }}
           navigation={true}
                     style={{
         '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
          '--swiper-pagination-color': '#DE562C',
        }}
       
       
          modules={[Autoplay,Navigation]}
        >
        {
         brandData && brandData?.slice(11).map((brand)=>(
           <SwiperSlide>
           <Link to={`/shop/brand/${brand.name}`} >
           <div className=' ' >
               
                    <img className='lg:rounded-[10px] rounded-[5px] lg:w-[110px] w-[70px] ' src={brand.image} />
                  
             
                
                </div>
                </Link>
             </SwiperSlide>
         ))
        }     
          </Swiper>
          
            </div>
            
              
        </div>
    </div>
  )
}

export default BrandList