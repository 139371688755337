import React from 'react'
import ProductCard from '../home/ProductCard'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
const RelatedProducts = ({relatedProduct}) => {
  return (
    <div className='my-8 xl:mx-8 mx-4' >
        <h4 className='text-[1.4rem] font-[600]' >You May Also Like</h4>
        <div className='mt-4' >
         <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          breakpoints={{
       300: {
          width: 300,
          slidesPerView: 1.1,
        },
         350: {
          width: 350,
          slidesPerView: 1.6,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
          1000:{
          width:1000,
          slidesPerView:3.1
        },
       
         1440:{
          width:1440,
          slidesPerView:4.2
        }
      }}
          autoplay={{
            delay: 2000,
            disableOnInteraction:true,
          }}
          loop={true}
          modules={[Autoplay]}
         >
            {relatedProduct && relatedProduct.map((product)=>(
               <SwiperSlide>
                <ProductCard product={product} />
                </SwiperSlide>
            ))}
            </Swiper>
        </div>
    </div>
  )
}

export default RelatedProducts