  import Apple from "../asset/brandList/apple.png"
import Asus from "../asset/brandList/asus.png"
import Google from "../asset/brandList/google.png"
import Nothing from "../asset/brandList/nothing.jpeg"
import Micromax from "../asset/brandList/micromax.png"
import Motorola from "../asset/brandList/motorola.png"
import Nokia from "../asset/brandList/nokia.png"
import Oneplus from "../asset/brandList/oneplus.png"
import Oppo from "../asset/brandList/oppo.png"
import Realme from "../asset/brandList/realme.png"
import Samsung from "../asset/brandList/samsung.png"
import Techno from "../asset/brandList/techno.png"
import Vivo from "../asset/brandList/vivo.png"
import Xiaomi from "../asset/brandList/mi.png"
import Infinix from "../asset/brandList/infinix.png"
import Karboon from "../asset/brandList/Karbonn.png"
import Lava from "../asset/brandList/lava.png"
import LG from "../asset/brandList/lg.png"
import Poco from "../asset/brandList/poco.png"
import Iqoo from "../asset/brandList/iqoo.png"
import Celkon from "../asset/brandList/celkon.png"


export const brandData = [
    {
            name:"Apple",
   
            image:Apple
        },
         {
            name:"Nothing",
   
            image:Nothing
        },
        {
            name:"Asus",
       
            image:Asus
        },
         {
            name:"Celkon",
       
            image:Celkon
        },
        
         {
            name:"Goole",
      
            image:Google
        },
       
        {
            name:"Infinix",
      
            image:Infinix
        },
         {
            name:"Karbonn",
      
            image:Karboon
        },
         {
            name:"Lava",
      
            image:Lava
        },
         {
            name:"LG",
      
            image:LG
        },
         
        
         
        {
            name:"Micromax",
     
            image:Micromax
        },
        {
            name:"Motorola",
     
            image:Motorola
        },
       {
            name:"Nokia",
         
            image:Nokia
        },
          {
            name:"Oneplus",
          
            image:Oneplus
        },
          {
            name:"Oppo",
      
            image:Oppo
        },
         {
            name:"Realme",
    
            image:Realme
        },
        {
            name:"Samsung",
      
            image:Samsung
        },
       {
            name:"Techno",
       
            image:Techno
        },
         {
            name:"Vivo",
    
            image:Vivo
        }, 
          {
            name:"Xiaomi",
        
            image:Xiaomi
        },
        
       
        
         {
            image:Poco,
            name:"Poco",
        },
        {
            image:Iqoo,
            name:"Iqoo",
        },
      
]