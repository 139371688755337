import React from 'react'
import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { BellAlertIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import MobileCondition from "../../asset/mobilecondition.svg"
import MainMobileCondition from "../../asset/offer/back-camera.png"
import MobileCondition2 from "../../asset/mobilecondition2.svg"
import MobileCondition3 from "../../asset/mobilecondition3.svg"
const ProductContent = ({product,addProduct,handleNotify,condition,setCondition}) => {
  const active = "border-[#1287E3] border-[1px] text-[#1287E3] bg-white"
  const inActive = "border-[1px] border-gray-300 bg-gray-200 "
  console.log(product.condition)
  return (
    <div style={{fontFamily:"DM Sans"}} className='lg:col-span-3 mx-2 lg:ml-6'>
        <div>
            <h4 className='font-[600] text-[1.3rem] mt-3 xl:mt-0 xl:text-[1.8rem] text-[#121212] ' >Refurbished {product.productName && product.productName}</h4>
            {/* <p className='text-gray-700 text-[1.5rem]' >(Purple)</p> */}
            <p className='text-gray-600 font-[400] my-0 text-[1rem] xl:text-[1rem]'>{product?.ram && product.ram} {product?.ram && ","} {product?.storage}, {product?.color}</p>
            <div className='flex items-center my-0 justify-start xl:justify-start' >
                <p className='text-[#1287E3] font-[700] text-[1.1rem] xl:text-[1.6rem] mr-3 ' >Rs.{product.showPrice && product.showPrice}</p>
                <p className='text-gray-400 text-[0.9rem] xl:text-[1rem] line-through mr-3 ' >Rs. {product.realPrice && product.realPrice}</p>
                 <p className='text-green-500 text-[0.9rem] xl:text-[1rem] font-[500]' >Save {product?.realPrice-product?.price}</p>
                
            </div>
           { product.stock === "out of stock" && <p className='text-red-400 text-[0.8rem]' >The Product is Out of Stock</p> }
          { product.stock === "in stock" ?   <div className='flex items-center justify-start' >
                <button onClick={addProduct} className='bg-[#1287E3] border-[#1287E3] border-[1px] lg:px-8 px-3 py-2 lg:py-3 mr-3 text-white text-[.7rem] lg:text-[1rem] w-full  lg:w-[190px] my-4 rounded-[10px]  flex items-center justify-center xl:justify-center ' >
               <ShoppingBagIcon className='mr-3 w-[20px] ' />  <p className='font-[500]' >Add to Cart</p>   
                </button>
               
            </div> : 
            <div>
                <button onClick={handleNotify} className='bg-green-400 px-3 py-2 text-white text-[1rem] w-full xl:w-[250px] my-4 rounded-[10px]  flex items-center justify-center xl:justify-start ' >
               <BellAlertIcon className='mr-3 w-[20px] ' />  <p className='font-[500]' >Notify me when Available</p>   
                </button>
            </div>
            }
            
            
              <div className='xl:my-4 my-4 lg:my-2' >
                <h4>Conditions:</h4>
                <div className='flex items-center justify-start my-2' >
                <div value="fair" className={` flex flex-col items-center mr-3 justify-center px-2 py-2  w-[120px] rounded-[10px] ${product.condition==="fair" ? active : inActive } `} >
                  <img className='lg:w-[40px] w-[30px]' src={MainMobileCondition} alt="" />
                  <p className='mt-2 text-[0.8rem] lg:text-[1.1rem]' >Fair</p>
                </div>
                 <div className={` flex flex-col items-center mr-3 justify-center px-2 py-2 ${product.condition==="good" ? active : inActive } w-[120px] rounded-[10px]`} >
                  <img className='lg:w-[40px] w-[30px] ' src={MainMobileCondition} alt="" />
                  <p className='mt-2 text-[0.8rem] lg:text-[1.1rem]' >Good</p>
                </div>
                 <div className={`flex flex-col items-center mr-3 justify-center px-2 py-2 ${product.condition==="superb" ? active : inActive } w-[120px] rounded-[10px]`} >
                  <img className='lg:w-[40px] w-[30px]' src={MainMobileCondition} alt="" />
                  <p className='mt-2 text-[0.8rem] lg:text-[1.1rem]' >Superb</p>
                </div>
                
                </div>
                
              </div>
             
            {/* <div className='my-6' >
             <p className='text-[#121212] font-[600] text-[1.5rem] ' >
              Basic Info:
            </p>
            <div>
              <p className='text-gray-500 text-[1.2rem]' >1) Battery: {product?.brandSpecifications && product?.brandSpecifications[1]?.details[6]?.value}, {product.brandSpecifications && product?.brandSpecifications[1]?.details[7]?.value && product?.brandSpecifications[1]?.details[7]?.value} </p>
               <p className='text-gray-500 text-[1.2rem]' >2) Display Resolution: {product?.brandSpecifications && product?.brandSpecifications[1]?.details[1]?.value} </p>
               <p className='text-gray-500 text-[1.2rem]' >3) Chipset: {product?.brandSpecifications && product?.brandSpecifications[1]?.details[5]?.value} </p>
                <p className='text-gray-500 text-[1.2rem]' >4) Video Pixel: {product?.brandSpecifications && product?.brandSpecifications[1]?.details[5]?.value} </p>
            </div>
            </div> */}
        </div>
    </div>
  )
}

export default ProductContent