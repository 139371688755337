import React,{useState,useEffect} from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  
  Button,
} from "@material-tailwind/react";
import Logo from "../asset/logo.png"
import GoogleIcon from "../asset/search.png"
import {useDispatch,useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import { signUpUsingEmail,loginUsingEmail,signInUsingGoogle,signUpUsingGoogle, loginUsingPhone } from '../actions/userActions';
import { PhoneIcon,EnvelopeIcon } from '@heroicons/react/24/outline';
import { useCountries } from "use-react-countries";
import Email from '../components/Login/Email';
import {
  doc,
  setDoc,
  addDoc,
  collection,
  where,
  query,
  getDoc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import { db } from '../firebase.config'
import MobileandOTP from '../components/Login/MobileandOTP';
const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
     const [emailError, setEmailError] = useState(false)
    const [emailErrorMessage, setEmailErrorMessage] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [active, setActive] = useState("phone")
    const { error, loading, isAuthenticated,users } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch()
      const navigate = useNavigate()

      

  const handleRegister = async ()=>{
    const userRef = collection(db, "user");
    const emailQuery = query(userRef, where("email", "==", email));
    const getEmailInfo = await getDocs(emailQuery);
      var emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
     
    if(email!=="" && getEmailInfo.docs.length===0 ){
    
      alert("Email Does Not Present")
      setEmailError(true)
      setEmailErrorMessage("Email Does Not Present")
    }
   if(email=== ""  || emailRegex.test(email)===false){
      alert("Enter Valid Email")
      setEmailError(true)
      setEmailErrorMessage("Enter a Valid Email")
    }
     if( emailRegex.test(email)===true){
     setEmailError(false)
    }
    
   
     if(password===""  ){
      alert("Enter Valid Password")
      setPasswordError(true)
      setPasswordErrorMessage("Enter a Valid Password")
    }
    else{
     dispatch(loginUsingEmail(email,password))
    if(!error){
      setEmail('')
      setPassword('')
    }
    }
   
    
  }
  
  
  const handleGoogleLogin = async () =>{
    
    dispatch(signUpUsingGoogle())
  }

  useEffect(() => {
    if(isAuthenticated){
      navigate(-1)
    }
  }, [isAuthenticated])

  return (
    <div style={{ fontFamily: "DM Sans" }} className='w-full h-[100vh] bg-[#e0e7ed] flex items-center justify-center ' >
       {active==="email" ? <Email emailError={emailError} emailErrorMessage={emailErrorMessage} passwordError={passwordError} passwordErrorMessage={passwordErrorMessage} showPassword={showPassword} setShowPassword={setShowPassword} active={active} setActive={setActive} password={password} setEmail={setEmail} setPassword={setPassword} handleGoogleLogin={handleGoogleLogin} handleRegister={handleRegister} email={email}  />
       
        : <MobileandOTP active={active} setActive={setActive} handleGoogleLogin={handleGoogleLogin}  /> }
    </div>
  )
}

export default Login