import React from 'react'
import Iphone from "../../asset/iphone.png"
import Oneplus from "../../asset/oneplus8.png"
import Realme from "../../asset/realme8.png"
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import ProductIphoneOne from "../../asset/productIphone.jpg"
import ProductOneplusOne from "../../asset/productoneplus1.jpg"
import Productrealmeone from "../../asset/productrealmeone.jpg"
import ProductCard from './ProductCard'
import { Link } from 'react-router-dom'
const HotDeals = ({premiumMobiles}) => {
    const data = [
       
    ]
  return (
    <div style={{fontFamily:"DM Sans"}}  className='lg:py-2 py-3 px-3 lg:px-1' >
        <div className='lg:px-12' >
        {/* <p style={{fontFamily:"Bebas Neue"}} className='text- tracking-widest text-[#1287E3] ' >Trending </p> */}
            <h2 style={{fontFamily:"DM Sans"}} className='font-[600]   text-[1rem] lg:text-[1.5rem] ' >
                Premium Mobiles
            </h2>
            <div className='pb-3 pt-2 lg:pt-4' >
               <Swiper   spaceBetween={0}
          className=""
          draggable={true}
          breakpoints={{
              300: {
          width: 300,
          slidesPerView: 1.1,
        },
         350: {
          width: 350,
          slidesPerView: 1.6,
        },
        780: {
          width: 780,
          slidesPerView: 3,
        },
          1000:{
          width:1000,
          slidesPerView:3.1
        },
       
         1440:{
          width:1440,
          slidesPerView:4.2
        }
      }}
      style={{
         '--swiper-navigation-size': '25px',
         '--swiper-navigation-color': '#DE562C',
          '--swiper-pagination-color': '#DE562C',
        }}
           navigation={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction:false,
          }}
          loop={false}
          modules={[Autoplay,Navigation]}
         >
            {
              premiumMobiles &&  premiumMobiles.map((product)=>(
                    <SwiperSlide>
                   
                        <ProductCard product={product} />
                     
                    </SwiperSlide>
                ))
            }
          </Swiper>
            </div>

        </div>
    </div>
  )
}

export default HotDeals